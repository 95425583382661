import { defineStore, storeToRefs } from 'pinia';
import { useAccountStore } from './account';

export const useTranslationStore = defineStore('translation', {
  state: () => {
    const { _region: region } = storeToRefs(useAccountStore());
    return {
      region: region,
      links: {
        termsAndAgreement: {
          'sv-SE': 'anvandarvillkor',
          'nb-NO': 'brukervilkar',
        },
        privacyPolicy: {
          'sv-SE': 'integritetspolicy',
          'nb-NO': 'personvernerklaering',
        },
        salesDeliveryPolicy: {
          'sv-SE': 'forsaljnings-och-leveransvillkor',
          'nb-NO': 'salgs-og-leveringsbetingelser',
        },
      },
    };
  },
  getters: {
    localeByDivision(state) {
      const division = state.region ?? 'EDS'; //TODO: Check if this actually works as this should be a Ref<string>
      const localeMap = {
        EDS: 'sv-SE',
        BOK: 'en-US',
        SAN: 'nb-NO',
        FIN: 'fi-FI',
        KRI: 'de-DE',
      };
      return localeMap[division];
    },
    // Peel off the translations and only serve the current division's translation
    divisionLinks(state): Record<string, string> {
      const locale = this.localeByDivision;

      return Object.keys(state.links).reduce((acc, key) => {
        const linkObj = state.links[key];
        // Use the current locale or the first translation if no match
        acc[key] = linkObj?.[locale] ?? Object.values(linkObj)[0];
        return acc;
      }, {});
    },
  },
});
