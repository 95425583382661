<template>
  <nav class="tabs is-centered is-size-7 is-size-8-mobile" role="navigation">
    <ul>
      <li
        v-for="item in items"
        :key="item.label"
        :class="[
          'is-uppercase',
          'tracking-wide',
          $route.meta.name === item.name || $route.meta.parent === item.name ? 'is-active' : null,
        ]"
      >
        <router-link :to="item.href" class="is-link">{{ item.label }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
  import { useAccountStore } from '@/stores/account';
  import { AxiosError } from 'axios';
  import { mapState, mapActions } from 'pinia';

  export default {
    name: 'AccountNavigation',
    data() {
      return {
        canManageContacts: false,
      };
    },
    computed: {
      ...mapState(useAccountStore, ['status', 'user', 'region']),
      items: function () {
        return [
          {
            label: this.$t('generic.orders'),
            href: '/mina-sidor',
            name: 'orders',
          },
          {
            label: this.$t('generic.offer'),
            href: '/mina-sidor/quotes',
            name: 'quotes',
          },
          {
            label: this.$t('generic.tickets'),
            href: '/mina-sidor/tickets',
            name: 'tickets',
          },
          ...(this.status.PRODUCT_API_ENABLE && this.user && !this.user?.isBendersEmployee
            ? [
                {
                  label: this.$t('list.shopping_list.label'),
                  href: '/mina-sidor/lists',
                  name: 'lists',
                },
              ]
            : []),
          // ...(this.isWeberUser
          //   ? [
          //       {
          //         label: this.$t('list.weber'),
          //         href: '/mina-sidor/weber',
          //         name: 'weber',
          //       },
          //     ]
          //   : []),
          ...(this.user && this.user?.contacts?.length && !this.user?.isBendersEmployee
            ? [
                {
                  label: this.$t('account.user_details'),
                  href: '/mina-sidor/account',
                  name: 'account',
                },
              ]
            : []),
          ...((this.user?.isBendersEmployee || this.user?.customers?.some((c) => c.customerGroup == 'MUC')) &&
          !['FIN', 'KRI'].includes(this.region)
            ? [
                {
                  label: this.$t('rest_stock.label'),
                  href: '/mina-sidor/reststock',
                  name: 'reststock',
                },
              ]
            : []),
          {
            label: this.$t('generic.addresses'),
            href: '/mina-sidor/addresses',
            name: 'addresses',
          },
          ...(this.user && (this.canManageContacts || this.user?.isBendersEmployee)
            ? [
                {
                  label: this.$t('generic.users'),
                  href: '/mina-sidor/users',
                  name: 'users',
                },
              ]
            : []),
        ];
      },
    },
    mounted() {},
    methods: {
      ...mapActions(useAccountStore, ['login', 'logout', 'userCan']),
    },
    watch: {
      user: {
        deep: true,
        immediate: true,
        handler() {
          this.userCan('manage_contacts')
            .then((canManageContacts) => {
              this.canManageContacts = canManageContacts;
            })
            .catch((e) => {
              if ((e as AxiosError).response?.status === 401) {
                this.canManageContacts = false;
              } else {
                console.error(e);
              }
            });
        },
      },
    },
  };
</script>
