import { computed, reactive, type Ref, watch } from 'vue';
import { Emittable } from '@/data/productlist/generic.ts';

import _ from 'lodash';

// Requires Articles class
export class Warehouse extends Emittable {
  composable: ({}: { list: Ref<any> }) => any;
  constructor() {
    super();
    const self = this;
    this.composable = ({ list }) => {
      const state = reactive({
        c_selected_warehouse: null as any, // Should only be used internally by module
        c_warehouse_stock: {} as any,

        p_c_selected_warehouse: computed({
          get() {
            if (typeof state.c_selected_warehouse === 'string') {
              return {
                id: state.c_selected_warehouse,
              };
            } else {
              return (
                state.c_selected_warehouse ||
                (list.value?.warehouse
                  ? {
                      id: list.value.persistent.list.warehouse,
                    }
                  : null)
              );
            }
          },
          set(warehouse) {
            state.c_selected_warehouse = warehouse;
            if (list.value && warehouse) list.value.warehouse = warehouse?.id ?? warehouse;
          },
        }),
      });

      watch(
        () => state.p_c_selected_warehouse,
        (warehouse) => {
          if (!list.value || !warehouse?.id) return;

          // Update each row with the correct warehouse
          list.value.rows.forEach((row) => {
            self.emit(this, {
              name: '_emit',
              event: {
                row: row.id,
                name: 'update',
                key: 'warehouse',
                value: warehouse.id,
              },
            });
          });
        },
        { immediate: true }
      );

      return state;
    };
    this.mixin = {
      data() {
        return {
          c_selected_warehouse: null, // Should only be used internally by module
          c_warehouse_stock: {},
        };
      },
      computed: {
        p_c_selected_warehouse: {
          get() {
            if (typeof (this as any).c_selected_warehouse === 'string') {
              return {
                id: (this as any).c_selected_warehouse,
              };
            } else {
              return (
                (this as any).c_selected_warehouse ||
                ((this as any).persistent?.list?.warehouse
                  ? {
                      id: (this as any).persistent.list.warehouse,
                    }
                  : null)
              );
            }
          },
          set(warehouse) {
            (this as any).c_selected_warehouse = warehouse;
            if ((this as any).persistent?.list && warehouse)
              (this as any).persistent.list.warehouse = warehouse?.id ?? warehouse;
          },
        },
      },
      watch: {
        p_c_selected_warehouse: {
          handler(warehouse) {
            const data = (this as any).persistent;
            if (!data || !data.list || !data.rows || !warehouse?.id) return;

            // Update each row with the correct warehouse
            data.rows.forEach((row) => {
              self.emit(this, {
                name: '_emit',
                event: {
                  row: row.id,
                  name: 'update',
                  key: 'warehouse',
                  value: warehouse.id,
                },
              });
            });
          },
          immediate: true,
        },
      },
    };
  }

  init() {
    this.on('warehouse-stock-updated', (vm, event) => {
      vm.c_warehouse_stock[event.row] = event.value;
    });
  }

  modify(vm, rows) {
    for (const row of rows) {
      if (!row.warehouse) {
        row.warehouse = vm.p_c_selected_warehouse?.id;
      }
    }

    return rows;
  }
}
