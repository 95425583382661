/* eslint-disable no-unused-vars */
import _ from 'lodash';

import { type Address as AddressType } from '@/types/Checkout.ts';
import { reactive, computed, watch, type Ref } from 'vue';

import { Emittable } from '@/data/productlist/generic.ts';
import { getCustomer, getCustomerAddresses } from '@/data/api/kundportal/requests';

/* Address module
 */
export class Address extends Emittable {
  composable: ({}: { customerNumber: Ref<string> }) => any;
  constructor() {
    super();
    this.composable = ({ customerNumber }) => {
      const state = reactive({
        c_current_customer_address: null as any,
        c_customer_addresses: null as any,
        c_selected_stock_address: null as any,
        c_custom_address: reactive<AddressType>({
          name: '',
          address1: '',
          address2: '',
          postalCode: '',
          city: '',
          country: 'Sverige',
          countryCode: 'SE',
          is_custom: true,
          phoneNumber1: '',
          phoneNumber2: '',
          notificationTo: '',
        }),
        p_c_addresses: computed(() => {
          let addresses = { Billing: {}, Stock: {} };
          if (customerNumber.value && state.c_customer_addresses) {
            addresses = {
              Billing: state.c_customer_addresses['Billing']
                ? state.c_customer_addresses['Billing'][customerNumber.value]
                : {},
              Stock: state.c_customer_addresses['Stock'] && state.c_customer_addresses['Stock'][customerNumber.value],
            };
          }

          return addresses;
        }),
        p_c_defaultStockAddress: computed(() => {
          if (!customerNumber.value || !state.c_customer_addresses) return null;
          let addresses = state.c_customer_addresses;
          if (addresses['Default']) return addresses['Default'];
          return null;
        }),
        p_c_address: computed(() => {
          let address = { customer: state.c_current_customer_address || {}, stock: {}, billing: null };
          if (state.c_current_customer_address && state.c_customer_addresses) {
            let addresses = state.c_customer_addresses;
            address.billing = addresses?.['Billing']?.[customerNumber.value]
              ? addresses['Billing'][customerNumber.value][0]
              : {};
            address.stock =
              state.c_selected_stock_address ||
              state.p_c_defaultStockAddress ||
              (addresses?.['Stock']?.[customerNumber.value]
                ? addresses['Stock'][customerNumber.value].find((address) => address.same_as_billing) ||
                  addresses['Stock'][state.c_current_customer_address][0]
                : null) ||
              'new_address';
          }
          return address;
        }),
        p_c_shipping_address: computed(() => {
          let data = state.p_c_address?.stock || {};

          if (state.c_selected_stock_address === 'new_address') {
            data = { ...data, ...state.c_custom_address };
          }

          return data;
        }),
      });

      // Watchers
      watch(
        () => state.p_c_defaultStockAddress,
        (address) => {
          if (!state.c_selected_stock_address) state.c_selected_stock_address = address;
        }
      );

      watch(
        () => customerNumber.value,
        (val, oldVal) => {
          if (!val) {
            state.c_current_customer_address = null;
            state.c_customer_addresses = null;
            return;
          }

          getCustomer(val).then((customer) => {
            state.c_current_customer_address = customer;
          });
          getCustomerAddresses(val).then((addresses) => {
            state.c_customer_addresses = addresses;
          });

          // Clear stock address if customer number is changed
          if (!oldVal || val == oldVal || state.c_selected_stock_address == 'new_address') return;
          state.c_selected_stock_address = null;
        },
        { immediate: true }
      );
      // watch(
      //   () => state.p_c_address.customer.currency,
      //   (val) => {
      //     price_currency.value = val;
      //   }
      // );

      return state;
    };
    this.mixin = {
      data() {
        return {
          c_current_customer_address: null as any,
          c_customer_addresses: null as any,
          c_selected_stock_address: null as any,
          c_custom_address: {
            name: '',
            address1: '',
            address2: '',
            postalCode: '',
            city: '',
            country: 'Sverige',
            countryCode: 'SE',
            is_custom: true,
            phoneNumber1: '',
            phoneNumber2: '',
            notificationTo: '',
          },
        };
      },
      // asyncComputed: {
      //   async c_current_customer_address() {
      //     if (!this.p_c_customer_number) return null;

      //     return await getCustomer(this.p_c_customer_number);
      //   },
      //   async c_customer_addresses() {
      //     if (!this.p_c_customer_number) return null;

      //     const data = await getCustomerAddresses(this.p_c_customer_number);

      //     return data;
      //   },
      // },
      computed: {
        p_c_addresses() {
          let addresses = { Billing: {}, Stock: {} };
          if (this.p_c_customer_number && this.c_customer_addresses) {
            addresses = {
              Billing: this.c_customer_addresses['Billing']
                ? this.c_customer_addresses['Billing'][this.p_c_customer_number]
                : {},
              Stock: this.c_customer_addresses['Stock'] && this.c_customer_addresses['Stock'][this.p_c_customer_number],
            };
          }

          return addresses;
        },
        p_c_defaultStockAddress() {
          if (!this.p_c_customer_number || !this.c_customer_addresses) return null;
          let addresses = this.c_customer_addresses;
          if (addresses['Default']) return addresses['Default'];
          return null;
        },
        p_c_address() {
          let address = { customer: this.c_current_customer_address || {}, stock: {}, billing: null };
          if (this.c_current_customer_address && this.c_customer_addresses) {
            let addresses = this.c_customer_addresses;
            address.billing = addresses?.['Billing']?.[this.p_c_customer_number]
              ? addresses['Billing'][this.p_c_customer_number][0]
              : {};
            address.stock =
              this.c_selected_stock_address ||
              this.p_c_defaultStockAddress ||
              (addresses?.['Stock']?.[this.p_c_customer_number]
                ? addresses['Stock'][this.p_c_customer_number].find((address) => address.same_as_billing) ||
                  addresses['Stock'][this.c_current_customer_address][0]
                : null) ||
              'new_address';
          }
          return address;
        },
        p_c_shipping_address() {
          let data = this.p_c_address?.stock || {};

          if (this.c_selected_stock_address === 'new_address') {
            data = { ...data, ...this.c_custom_address };
          }

          return data;
        },
      },
      watch: {
        p_c_defaultStockAddress(address) {
          if (!this.c_selected_stock_address) this.c_selected_stock_address = address;
        },
        p_c_customer_number: {
          immediate: true,
          handler(val, oldVal) {
            if (!val) {
              (this as any).c_current_customer_address = null;
              (this as any).c_customer_addresses = null;
              return;
            }

            getCustomer(val).then((customer) => {
              (this as any).c_current_customer_address = customer;
            });
            getCustomerAddresses(val).then((addresses) => {
              (this as any).c_customer_addresses = addresses;
            });

            // Clear stock address if customer number is changed
            if (!oldVal || val == oldVal || (this as any).c_selected_stock_address == 'new_address') return;
            (this as any).c_selected_stock_address = null;
          },
        },
        'p_c_address.customer.currency'(val) {
          this.c_price_currency = val;
        },
      },
    };
  }
}
