import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

const jsonSerializer = {
  read: (v: any) => (v ? JSON.parse(v) : null),
  write: (v: any) => JSON.stringify(v),
};

export const useDebugStore = defineStore('debug', {
  state: () => ({
    debugMode: useStorage<boolean>('debugMode', null, sessionStorage, {
      serializer: jsonSerializer,
    }),
  }),
  actions: {
    setDebugMode(value: boolean) {
      this.debugMode = value;
    },
  },
  getters: {},
});
