<template>
  <section class="section cols-2">
    <div class="columns is-centered">
      <img width="250px" height="250px" class="benders-logo" src="@/assets/images/benders.svg" alt="Benders logotype" />
    </div>
    <div class="container is-max-desktop">
      <div class="overlay-wrapper">
        <div id="info">
          <p>{{ $t('browser.not_supported', { name: browser.name }) }}</p>
          <p>{{ $t('browser.download_new_browser') }}</p>
        </div>
        <div id="browserlist">
          <table class="logos" id="browserlist">
            <tbody>
              <tr>
                <td class="b">
                  <a
                    class="l"
                    href="https://www.microsoft.com/sv-se/edge"
                    target="_blank"
                    rel="noopener"
                    :title="$t('browser.edge_download')"
                  >
                    <img width="120px" :src="edgeImage" />
                    <br />
                    <span class="bro">Edge</span>
                    <span class="vendor">Microsoft</span>
                  </a>
                </td>
                <td class="b">
                  <a
                    class="l"
                    href="https://www.mozilla.org/sv-SE/firefox/new/"
                    target="_blank"
                    rel="noopener"
                    :title="$t('browser.firefox_download')"
                  >
                    <img width="120px" :src="firefoxImage" />
                    <br />
                    <span class="bro">Firefox</span>
                    <span class="vendor">Mozilla Foundation</span>
                  </a>
                </td>
                <td class="b">
                  <a
                    class="l"
                    href="https://www.google.se/chrome/browser/desktop/"
                    target="_blank"
                    rel="noopener"
                    :title="$t('browser.chrome_download')"
                  >
                    <img width="120px" :src="chromeImage" />
                    <br />
                    <span class="bro">Chrome</span>
                    <span class="vendor">Google</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="whatnow">{{ $t('browser.reasons_to_update') }}</div>
        <div class="whatnow-small">{{ $t('browser.download_is_secure') }}</div>
      </div>
      <!-- <div id="advantages-wrapper">
                <h2>Varför behöver jag en modern och uppdaterad webbläsare?</h2>
                <ul class="advantages">
                    <li id="security">
                        <i class="far fa-shield-alt advantages-icon"></i>
                        <h3>Säkerhet</h3>
                        <div>Nyra och uppdaterade webbläsare skyddar dig mot virus, bedrägerier och andra hot på webben. Äldre webbläsare har säkerhetshål som är lösta i nyare webbläsare.</div>
                    </li>
                    <li id="speed">
                        <i class="far fa-tachometer advantages-icon"></i>
                        <h3>Prestanda och hastighet</h3>
                        <div>Varje ny generation av webbläsare ökar prestandan och hastigheten av webben.</div>
                    </li>
                    <li id="fun">
                        <i class="far fa-cogs advantages-icon"></i>
                        <h3>Nya teknologier</h3>
                        <div>Du kan se och anvnda webbsidor som använder den senaste teknologin för att leverera en bättre upplevelse.</div>
                    </li>
                    <li id="comfort">
                        <i class="far fa-couch advantages-icon"></i>
                        <h3>Bättre användbarhet</h3>
                        <div>Ha en bättre upplevelse med nya funktioner, tillägg och bättre anpassnings möjligheter.</div>
                    </li>
                </ul>
            </div> -->
    </div>
  </section>
</template>

<script lang="ts">
  import edgeImage from '@/assets/images/browsers/edge.png';
  import firefoxImage from '@/assets/images/browsers/firefox.png';
  import chromeImage from '@/assets/images/browsers/chrome.png';

  export default {
    name: 'OutdatedBrowserOverlay',
    data() {
      return {
        edgeImage,
        firefoxImage,
        chromeImage,
      };
    },
    computed: {
      browser() {
        // figure out the browser
        const ua = navigator.userAgent.toLowerCase();
        return {
          name: (ua.match(/(edge|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/) || [])[1],
          version: (ua.match(/(?:edge|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/) || [])[1],
        };
      },
    },
  };
</script>

<style scoped lang="sass">
  .overlay-wrapper
      margin: 15px 0
      // box-shadow: 0 0 8px #888
      background: #fff4f4
      text-align: center
      padding: 2px

  #info
      text-align: center
      margin: 13px 25px
      font-size: 20px
      font-weight: 400
  .logos
      width: 100%
      td
          vertical-align: top
          padding: 10px
          border: none
          background: 0 0
          width: 33%

  a.l
      display: block
      padding: 4px
      text-decoration: none
      text-align: center
      border: 2px solid transparent
      position: relative

      .bro
          white-space: nowrap
          text-decoration: underline
          font-size: 1.25rem

      .vendor
          color: #aaa
          text-align: center
          font-size: 0.75rem
          display: block

  .whatnow
      text-align: center
      margin-top: 2rem
      font-size: 20px
      font-weight: 400

  .whatnow-small
      font-size: 15px
      text-align: center
      font-weight: 300

  .advantages-wrapper
      text-align: center

  .advantages
      font-size: 13px
      list-style: none
      padding-left: 0
      li
          background-repeat: no-repeat
          background-position: center top
          color: #777
          display: inline-block
          width: 50%
          text-align: center
          vertical-align: top
          background-size: 32px
          padding: 38px 7% 20px

      h3
          font-size: 1.5rem
          line-height: 30px
          margin-bottom: 0.5rem

  h2
      margin-top: 1.8em
      text-align: center
      font-size: 33px

  i.advantages-icon
      font-size: 5rem

  .benders-logo
      width: 250px
</style>
