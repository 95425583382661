<template>
  <div>
    <div
      v-for="category in filteredCategories"
      :key="category.slug"
      class="secondary-navigation-dropdown relative"
      style="height: '45px'"
    >
      <router-link
        :to="`/${route_slug}/${category.slug}`"
        :class="[
          'level-item',
          'is-link',
          'is-narrow',
          'is-uppercase',
          'tracking-wide',
          'is-size-7',
          'has-text-white',
          'secondary-navigation__item',
          ($route.params.category || $route.query.category) === category.slug
            ? 'secondary-navigation__item--active'
            : null,
        ]"
      >
        {{ category.name }}
      </router-link>
      <div class="level-item assortment">
        <nav class="tabs is-centered" role="navigation">
          <ul>
            <li
              v-for="assortment in category.assortments"
              :key="category.slug + assortment.name"
              class="is-uppercase tracking-wide is-size-7 is-size-8-mobile"
            >
              <router-link
                :to="{
                  path: `/${route_slug}/${category.slug}/`,
                  query: { artgroup: encodeURIComponent(assortment.name) },
                }"
              >
                {{ assortment.i18n || assortment.name }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { mapState, mapActions } from 'pinia';
  import { getProductCategories } from '@/data/api/kundportal/requests';
  import { useAccountStore } from '@/stores/account';

  export default {
    name: 'ProductNavigation',
    data() {
      return {
        route_slug: 'produktsortiment',
        productCategories: [] as any[],
      };
    },
    computed: {
      ...mapState(useAccountStore, ['status', 'user', 'locale', 'region']),
      filteredCategories() {
        return this.productCategories.filter((category) => this.user?.accessibleAssortments?.includes(category.slug));
      },
    },
    methods: {
      ...mapActions(useAccountStore, ['login', 'logout']),
      fetchProductCategories() {
        getProductCategories().then((e: any) => {
          this.productCategories = e.data;

          // Previously assortments were sent as pure strings, convert them into the new format if backend is still sending old format
          // (Can be removed once backends been fully updated)
          for (const cat of this.productCategories)
            cat.assortments = cat.assortments.map((a) => (typeof a === 'string' ? { key: a, name: a } : a));
        });
      },
    },
    created() {
      this.fetchProductCategories();
    },
    watch: {
      locale() {
        this.fetchProductCategories();
      },
      region() {
        this.fetchProductCategories();
      },
    },
  };
</script>
