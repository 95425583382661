import dayjs, { PluginFunc } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import swedish from 'dayjs/locale/sv';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';

// Define your plugin
const defaultFormatUtcPlugin: PluginFunc = (_option, dayjsClass, _dayjsFactory) => {
  dayjsClass.prototype.defaultFormatUtc = 'YYYY-MM-DDTHH:mm:ss[Z]';
};

dayjs.extend(defaultFormatUtcPlugin);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(LocalizedFormat);
dayjs.locale(swedish);

export default dayjs;

const _dayjs = dayjs();
export { _dayjs };
