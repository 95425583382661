<template>
  <div>
    <table style="width: 100%">
      <tr v-for="c of children" :key="c.name + c.uid" class="vue-component hidden">
        <div class="component-actions">
          <a @click="($event.target as HTMLElement)?.parentElement?.parentElement?.classList.toggle('hidden')">
            {{ c.name }}
          </a>
          <a @click="debug()">
            <span class="is-size-6 icon balloon" :title="$t('debug.view_in_console')">
              <font-awesome-icon :icon="['fas', 'terminal']" />
            </span>
          </a>
        </div>
        <div class="component-container">
          <InspectionTable :component="c.child" class="margin-large-left" />
        </div>
      </tr>
      <div>
        <table style="width: 100%">
          <tr v-for="v of vars" :key="component.uid + v.name">
            <span>{{ v.name }}</span>
            |
            <span>{{ v.type }}</span>
            |
            <span v-if="v.prop == null">null</span>
            <template v-else-if="['object', 'array', 'computed'].includes(v.type)">
              <a @click="debug(v.prop)">
                <i class="is-size-7">{{ $t('debug.view_in_console') }}</i>
              </a>
              <a @click="debug(v.prop)" style="float: right">
                <span class="is-size-6 icon balloon" :title="$t('debug.view_in_console')">
                  <font-awesome-icon :icon="['fas', 'terminal']" />
                </span>
              </a>
            </template>
            <span v-else>{{ v.prop }}</span>
          </tr>
        </table>
      </div>
    </table>
  </div>
</template>

<script lang="ts">
  import { PropType } from 'vue';

  // import _ from 'lodash'

  export default {
    name: 'InspectionTable',
    components: {},
    props: {
      component: {
        type: Object as PropType<any>,
        required: true,
      },
    },
    computed: {
      vars() {
        let vars = [] as any[];
        // Computed vars
        for (let prop in this.component?._computedWatchers) {
          vars.push({
            name: prop,
            type: 'computed',
            prop: this.component?.[prop],
          });
        }

        // Data vars
        for (let prop in this.component) {
          let type = typeof this.component[prop] as
            | 'string'
            | 'number'
            | 'boolean'
            | 'object'
            | 'function'
            | 'symbol'
            | 'undefined'
            | 'bigint'
            | 'array';
          if (type === 'function' || !Object.prototype.hasOwnProperty.call(this.component, prop) || /^[_$]/.test(prop))
            continue;
          if (Array.isArray(this.component?.[prop])) type = 'array';
          vars.push({
            name: prop,
            type: type,
            prop: this.component?.[prop],
          });
        }

        return vars;
      },
      children() {
        let children = [] as any[];

        children = this.component?.$children?.reduce((acc, child) => {
          if (!['InspectionTable', 'DebugInspector'].includes(child?.$options?._componentTag)) {
            acc.push({
              uid: child._uid,
              name: (child?.$vnode?.tag ?? 'Unnamed').split('-').pop(),
              child: child,
            });
          }

          return acc;
        }, []);

        return children;
      },
    },
    data() {
      return {};
    },
    watch: {},
    methods: {
      debug(val?: any) {
        console.dir(val ?? this);
        // '$options._componentTag' for name
        // https://github.com/calirojas506/vue-inspector for inspiration?

        // new idea
        // Show last order syncs
        // Show last sync
        // Add button to be able to dump vue components to console
      },
    },
    created() {},
  };
</script>

<style>
  .hidden > .component-container {
    display: none;
  }

  .component-actions {
    display: flex;
    justify-content: space-between;
  }

  .table-inspector tr:nth-child(even) {
    width: 100%;
    background: #fafafa;
  }

  .table-inspector tr:nth-child(odd) {
    width: 100%;
    background: #f5f5f5;
  }
</style>
