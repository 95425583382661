/* eslint-disable no-unused-vars */
import { reactive } from 'vue';

import { Emittable } from '@/data/productlist/generic.ts';
import { roundUnit } from '@/data/api/products/helpers';

import _ from 'lodash';
import i18n from '@/languages/i18n';

/* Pallet module
  @Requires
    - Articles

  @Variables
*/

const calcAmount = (item) => {
  return (item.amount || 0) * item.article?.unit[item.unit] || 0;
};
export class Pallets extends Emittable {
  palletWeights: Record<string, number | null>;
  palletIndex: Record<string, string> = {};
  constructor() {
    super();
    this.palletWeights = {
      '099900': 20,
      '099902': 20,
      '099903': 20,
      '099945': 5,
      '099946': 20,
      '099990': 20,
      '099920': null,
    };
    this.mixin = {
      data() {
        return {
          c_pallet_options: {
            pallets: true,
            splitPallet: true,
            minWeight: true,
          },
        };
      },
    };
    this.composable = () => {
      return reactive({
        c_pallet_options: {
          pallets: true,
          splitPallet: true,
          minWeight: true,
        },
      });
    };
  }

  getPalletLabel(artnr) {
    this.palletIndex = {
      '099900': i18n.global.t('pallet.benders_pallet'),
      '099902': i18n.global.t('pallet.pallet_non_returnable'),
      '099903': i18n.global.t('pallet.pallet_lasken'),
      '099945': i18n.global.t('pallet.pallet_BRS'),
      '099946': i18n.global.t('pallet.pallet_euro'),
      '099990': i18n.global.t('pallet.pallet_color'),
      '099920': i18n.global.t('pallet.pallet_split'),
    };

    return this.palletIndex[artnr];
  }

  isBelowMinimumPalletWeight(vm, rows) {
    if (!rows.length) return true;
    // No pallets if products weigh under a certain amount, unless at least one article has a minimumQuantity that is not 0
    if (vm.c_pallet_options.minWeight && !rows.some((row) => row?.article?.itemPackaging?.minimumQuantity !== 0)) {
      const hasRoofingArticle = rows.some((row) => row.article.category === 'tak');
      const weight = rows.reduce((sum, r) => {
        sum += r.weight;
        return sum;
      }, 0);

      if ((!hasRoofingArticle && weight < 15) || (hasRoofingArticle && weight < 4.5)) return true;
    }
    return false;
  }

  modify(vm, rows) {
    if (!rows.length || (!vm.c_pallet_options.pallets && !vm.c_pallet_options.splitPallet)) {
      return rows;
    }

    let pallets = [] as any[];

    // Combine article amounts by ArtNr & Packaging
    let combinedRows = this.combineByArtNr(rows);
    let combinedPallets = this.combineByPackaging(combinedRows);

    // Round any partial pallets up & remove empty pallets (probably just the splitpallet)"
    for (let key in combinedPallets) {
      if (combinedPallets[key].amount === 0) {
        delete combinedPallets[key];
        continue;
      }
      combinedPallets[key].amount = Math.ceil(combinedPallets[key].amount);
    }

    // Add pallets according to settings
    for (let p of Object.values(combinedPallets) as any[]) {
      switch ((p as any).artnr) {
        case '099920':
          pallets.push(p);
          break;
        default:
          if (vm.c_pallet_options.pallets && !this.isBelowMinimumPalletWeight(vm, rows)) pallets.push(p);
          break;
      }
    }

    // Iterate over pallets and set warehouse (Is required to fetch price)
    for (let p of pallets) {
      p.warehouse = rows[0].warehouse;
    }

    rows.push(...pallets);

    return rows;
  }

  combineByArtNr(rows) {
    return rows.reduce((obj, row) => {
      if (!row.article || !row.article.underlyingItems) return obj;

      const amount = ~~row.amount || 0;
      let underlyingItems = row.article.underlyingItems.reduce((arr, data) => {
        data.id = row.article.id;
        arr.push({
          id: row.id,
          article_id: data.id,
          artnr: data.itemNumber,
          unit: data.alternateUM,
          amount: data.quantity * amount,
          article: data,
        });
        arr[arr.length - 1].article.unit = { [data.alternateUM]: data.quantity };
        return arr;
      }, []);
      const items = [row, ...underlyingItems];
      for (let item of items) {
        const id = item.article.ArtNr;
        if (!obj[id]) {
          obj[id] = { ...item, amount: 0 };
        } else if (!['p', 'p2'].includes(item.unit)) {
          // Avoids issue with pallet specific calculations when merging same article
          const amount = calcAmount(obj[id]);
          obj[id] = { ...item };
          obj[id].amount = amount;
        }
        obj[id].amount += ~~(['p', 'p2'].includes(obj[id].unit) ? item.amount : calcAmount(item));
      }

      return obj;
    }, {});
  }

  combineByPackaging(rows) {
    const palletsByPackaging = {};
    const splitId = '099920';
    const pallets = {
      '099920': {
        id: splitId,
        article: {
          descriptionExt: this.getPalletLabel(splitId),
          ArtNr: splitId,
        },
        _meta: {
          addon: true,
          readonly: true,
          addon_type: 'pallet',
        },
        artnr: splitId,
        amount: 0,
        // salesUnit: this.palletPrices[splitId] && this.palletPrices[splitId].length > 0 ? this.palletPrices[splitId][0].salesPriceUnitOfMeasure : 'st',
        weight: this.palletWeights[splitId],
      },
    } as any;

    // Calculate by packaging
    for (let key in rows) {
      const row = rows[key];
      const amount = row.amount;
      const article = row.article;
      const palletAmount = ['p', 'p2'].includes(row.unit) ? ~~row.amount : null;
      let packaging = article.itemPackaging;

      if (!packaging && palletAmount == null) continue;
      else if (!packaging && palletAmount) {
        packaging = {
          itemNumber: '2935000',
          deliverySpecification: '099900',
          packaging: 'PL',
          packagingCode: 'P',
          palletCode: 0,
          quantity: 999999,
          standardQuantity: 999999,
          minimumQuantity: 0,
          maximumQuantity: 999999,
        };
      }

      // if (!this.warehouseStock[row.id]) continue

      let id = packaging.packaging;
      let currentAmount = (palletsByPackaging[id] ? palletsByPackaging[id].quantity : null) || 0;
      let newAmount;

      let splitPallet = (article.splitPallet || '').toLowerCase() === 'y';
      let palletCode = splitPallet ? 2 : packaging.palletCode || 0;
      if (packaging.standardQuantity === 0) {
        if (palletAmount === null) continue;
        // Skip if not a pallet
        else {
          palletCode = 0;
        }
        // palletCode = 1
        // createSentryWarning("Pallet Calculations: Cannot divide by 0, skipping pallet calc for this item", packaging) // Shouldn't happen, report issue
      }

      switch (palletCode) {
        case 0:
          // Do not account for other articles
          newAmount = currentAmount + (palletAmount ? palletAmount : Math.ceil(amount / packaging.standardQuantity));
          break;
        case 1:
          newAmount = currentAmount + 1; // Always 1, do not account for other articles
          break;
        case 2:
          // Account for other articles
          newAmount = currentAmount + (palletAmount ? palletAmount : amount / packaging.standardQuantity);
          break;
        default:
          continue;
      }

      if (!palletsByPackaging[id]) palletsByPackaging[id] = [];
      palletsByPackaging[id].push({
        ...packaging,
        splitPallet: splitPallet,
        quantity: newAmount,
      });
    }

    // Account for articles on same pallets
    for (let key in palletsByPackaging) {
      for (let i = 0; i < palletsByPackaging[key].length; i++) {
        let packagingPallet = palletsByPackaging[key][i];
        let id = packagingPallet.deliverySpecification;

        // Create pallet if necessary
        if (!pallets[id]) {
          pallets[id] = {
            id,
            article: {
              descriptionExt: this.getPalletLabel(id),
              ArtNr: id,
            },
            _meta: {
              addon: true,
              readonly: true,
              addon_type: 'pallet',
            },
            artnr: id,
            amount: 0,
            unit: null, // Has to be fetched from the price, so it's not set here :(
            weight: 0,
            baseunitAmount: {},
          };
        }

        pallets[id].amount += packagingPallet.quantity;
        pallets[id].baseunitAmount = {
          formatted: roundUnit(Math.ceil(pallets[id].amount), 0),
          value: roundUnit(Math.ceil(pallets[id].amount), 0, false),
        };

        if (this.palletWeights[id] !== null) {
          pallets[id].weight = (this.palletWeights?.[id] ?? 0) * Math.ceil(pallets[id].amount);
        }

        if (packagingPallet.splitPallet) {
          pallets[splitId].amount += Math.ceil(packagingPallet.quantity % 1);
          pallets[splitId].baseunitAmount = {
            formatted: roundUnit(pallets[splitId].amount, 0),
            value: roundUnit(pallets[splitId].amount, 0, false),
          };
        }
      }
    }

    return pallets;
  }

  stripPallets(rows) {
    // const pallets = {}
    for (let key in rows) {
      const row = rows[key];
      if (row._meta && row._meta.addon_type === 'pallet') {
        // pallets[key] = row
        delete rows[key];
      }
    }
    return rows;
  }
}
