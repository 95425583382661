/* eslint-disable no-unused-vars */
import { Emittable } from '@/data/productlist/generic.ts';
import { reactive, computed, type Ref } from 'vue';
import { formatWeight, formatCurrency } from '@/data/api/products/helpers';

import _ from 'lodash';

/* Totals module
  Requires:
  - Articles
  - Price (For price totals)
*/
export class Totals extends Emittable {
  composable: ({}: { rows: Ref<any[]>; currency?: Ref<string> }) => {
    p_c_total_weight: any;
    p_c_total_price: any;
  };
  constructor() {
    super();
    this.composable = ({ rows, currency }) => {
      const state = reactive({
        p_c_total_weight: computed(() => {
          let total = rows.value?.reduce((sum, r) => {
            sum += parseFloat(r.weight) || 0;
            return sum;
          }, 0);

          total = total?.toFixed(2);

          return { raw: !isNaN(total) ? total : 0, formatted: !isNaN(total) ? formatWeight(total) : '...' };
        }),
        p_c_total_price: computed(() => {
          const VAT = 0.25;
          const sum =
            rows.value?.reduce((sum, r) => {
              sum += parseFloat(r.article?.price?.price_total) || 0;
              return sum;
            }, 0) || 0;

          return {
            currency: currency?.value, // Fetched from price module
            price: {
              price: sum,
              tax: sum * VAT,
              total: sum * (1 + VAT),
            },
            formatted: {
              price: formatCurrency(sum),
              tax: formatCurrency(sum * VAT),
              total: formatCurrency(sum * (1 + VAT)),
            },
          };
        }),
      });

      return state;
    };
    this.mixin = {
      data() {
        return {};
      },
      computed: {
        p_c_total_weight() {
          let total = this.p_c_rows?.reduce((sum, r) => {
            sum += parseFloat(r.weight) || 0;
            return sum;
          }, 0);

          total = total?.toFixed(2);

          return { raw: !isNaN(total) ? parseFloat(total) : 0, formatted: !isNaN(total) ? formatWeight(total) : '...' };
        },
        p_c_total_price() {
          const VAT = 0.25;
          const sum =
            this.p_c_rows?.reduce((sum, r) => {
              sum += parseFloat(r.article?.price?.price_total) || 0;
              return sum;
            }, 0) || 0;

          return {
            currency: this.c_price_currency, // Fetched from price module
            price: {
              price: sum,
              tax: sum * VAT,
              total: sum * (1 + VAT),
            },
            formatted: {
              price: formatCurrency(sum),
              tax: formatCurrency(sum * VAT),
              total: formatCurrency(sum * (1 + VAT)),
            },
          };
        },
      },
    };
  }
}
