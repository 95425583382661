import { getArticleTitle } from '@/helpers';
import { Emittable } from '@/data/productlist/generic.ts';

/* Checkboxes module
 */
export class Checkboxes extends Emittable {
  constructor() {
    super();
    const self = this;
    this.mixin = {
      data() {
        return {
          c_checked_rows: {},
        };
      },
      computed: {
        p_c_hasCheckedRows() {
          for (let k in this.c_checked_rows) {
            if (this.c_checked_rows[k]) return true;
          }
          return false;
        },
        p_c_allRowsChecked: {
          get() {
            for (let k in (this as any).c_checked_rows) {
              if ((this as any).c_checked_rows[k] === false) return false;
            }
            return true;
          },
          set(event) {
            if ((event?.name ?? 'update') !== 'update' && (event?.key ?? 'checked') !== 'checked') return;
            const value = event?.value ?? event;
            // Emit/Update value for each row
            for (let row of (this as any).p_c_rows ?? []) {
              if ((this as any).c_checked_rows[row.id] === null) continue;
              self.updateCheckbox(this, {
                row: row,
                value: value,
              });
            }
          },
        },
        p_c_allRowsValid() {
          if (this.isM3Order) return true; // For new checkout, M3orders does not have to be checked
          if (!this.p_c_rows) return false;
          for (let row of this.p_c_rows) {
            if (row._meta?.readonly || row._meta?.addon) continue;
            if (this.c_checked_rows[row?.id] === null) return false;
          }
          return true;
        },
      },
      watch: {
        p_c_rows() {
          // set checked to true if there is not entry in c_checked_rows
          for (let row of (this as any).p_c_rows ?? []) {
            if (!(row.id in (this as any).c_checked_rows)) {
              self.updateCheckbox(this, {
                row: row,
                value: true,
              });
            }
          }
        },
      },
    };
  }

  init() {
    this.on('checkbox:update-checkbox', this.handleEvent.bind(this));
    this.on('checkbox:update-checkbox-availability', this.handleEvent.bind(this));
    this.on('store:add-checked-to-cart', this.handleEvent.bind(this));
  }

  // modify(vm, rows) {
  //     // check if row is checked
  //     for (let row of rows) {
  //         row.checked = vm.c_checked_rows[row.id] ?? null
  //     }
  //     return rows
  // }

  handleEvent(vm, event) {
    switch (event.name) {
      case 'checkbox:update-checkbox':
        this.updateCheckbox(vm, event);
        break;
      case 'checkbox:update-checkbox-availability':
        this.updateCheckboxAvailability(vm, event);
        break;
      case 'store:add-checked-to-cart':
        this.addCheckedToCart(vm);
        break;
    }
  }

  updateCheckbox(vm, event) {
    const rowId = event?.row?.id ?? event?.row;
    if (!rowId) return;
    const val = event.row?._meta?.readonly ? null : event.value;
    vm.c_checked_rows[rowId] = val;
  }

  updateCheckboxAvailability(vm, event) {
    const rowId = event?.row?.id ?? event?.row;
    if (!rowId) return;
    event.value = event.value ? vm.c_checked_rows[rowId] ?? false : null;

    this.updateCheckbox(vm, event);
  }

  addCheckedToCart(vm) {
    // const list = vm.persistent.list
    const rows = vm.persistent.rows;

    this.emit(vm, {
      name: '_emit',
      event: {
        name: 'store:add-to-cart',
        value: rows.reduce((acc, row) => {
          const article = vm.c_articles[row.article_id];
          if (!article) return acc;

          if (vm.c_checked_rows[row.id]) {
            acc.push({
              name: getArticleTitle(article),
              article_id: row.article_id,
              artnr: article.ArtNr,
              amount: row.amount,
              unit: row.unit,
              warehouse: vm.p_c_selected_warehouse?.id ?? '',
            });
          }
          return acc;
        }, []),
      },
    });
  }
}
