import { defineStore } from 'pinia';

const clearNotification = { clear: true } as Notification;

export interface NotificationButton {
  // name?: string;
  text: string;
  value: string;
  data?: unknown;
}

export interface Notification {
  message: string;
  timeout?: number;
  source?: string;
  color?: string;
  buttons?: NotificationButton[];
  clear?: boolean; // if true, will append a custom notification that will clear all notifications
}

export const useNotificationStore = defineStore({
  id: 'notification',
  state: () => ({
    notifications: [] as Notification[],
  }),
  actions: {
    addNotification(notification: Notification) {
      this.notifications.push(notification);
    },
    clearNotification(force?: boolean) {
      this.notifications = force ? [clearNotification] : [];
    },
    popNotification() {
      this.notifications.pop();
    },
  },
  getters: {
    notification(state) {
      return state.notifications;
    },
  },
});
