<script setup lang="ts">
  import RepresentativeViwer from '@/components/RepresentativeViewer.vue';
  import { getUserRepresentatives } from '@/data/api/kundportal/requests';
  import { computedAsync } from '@vueuse/core';
  import { storeToRefs } from 'pinia';

  import { useAccountStore } from '@/stores/account';

  const { user, region } = storeToRefs(useAccountStore());

  const representatives = computedAsync(async () => {
    if (!user.value?.user) {
      return [];
    }
    let result = await getUserRepresentatives(user.value.user.id);
    return result.data;
  }, []);
</script>

<template>
  <section class="section is-medium" style="border-top: 2px solid rgb(240, 240, 240)">
    <div class="container">
      <div class="columns is-mobile">
        <div class="column is-6">
          <h1 v-if="region == 'SAN'" class="title is-4" style="margin-bottom: 1rem; white-space: pre-wrap">
            {{ $t('contact_info.SAN.customerservice') }}:
          </h1>
          <h1 v-else class="title is-4" style="margin-bottom: 1rem; white-space: pre-wrap">
            {{ $t('contact_info.customerservice') }}:
          </h1>
          <div v-if="region == 'SAN'">
            <div>Mandag til fredag: 08:00 - 16:00</div>
            <div>Telefon: 33 45 63 50</div>
            <div>
              E-post:
              <a href="mailto:post@benders.no">post@benders.no</a>
            </div>
          </div>
          <div v-else class="is-grid contact-table">
            <div>
              <div class="is-size-6 is-size-7-mobile has-text-weight-semibold">
                {{ $t('contact_info.order_reception') }}:
              </div>
              <a href="mailto:order@benders.se">order@benders.se</a>
              <div>08:00 - 16:30</div>
            </div>
            <div>
              <div class="is-size-6 is-size-7-mobile has-text-weight-semibold">
                {{ $t('contact_info.aftermarket') }}:
              </div>
              <a href="mailto:backoffice@benders.se">backoffice@benders.se</a>
              <div>07:30 - 16:00</div>
            </div>
            <div>
              <div class="is-size-6 is-size-7-mobile has-text-weight-semibold">
                {{ $t('contact_info.customer_invoice') }}:
              </div>
              <a href="mailto:kundfaktura@benders.se">kundfaktura@benders.se</a>
              <div>07:30 - 16:00</div>
            </div>
            <div>
              <div class="is-size-6 is-size-7-mobile has-text-weight-semibold">{{ $t('contact_info.quote') }}:</div>
              <a href="mailto:offert@benders.se">offert@benders.se</a>
              <div>07:30 - 16:00</div>
            </div>
            <div>
              <div class="is-size-6 is-size-7-mobile has-text-weight-semibold">
                {{ $t('contact_info.technical_support') }}:
              </div>
              <a href="mailto:teknisksupport@benders.se">teknisksupport@benders.se</a>
              <div>07:30 - 16:00</div>
            </div>
            <div>
              <div class="is-size-6 is-size-7-mobile has-text-weight-semibold">{{ $t('contact_info.delivery') }}:</div>
              <a href="mailto:leverans@benders.se">leverans@benders.se</a>
              <div>07:30 - 16:00 {{ $t('pikaday.month_short.april') }} - {{ $t('pikaday.month_short.october') }}</div>
              <div>07:30 - 15:30 {{ $t('pikaday.month_short.november') }} - {{ $t('pikaday.month_short.march') }}</div>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <RepresentativeViwer :representatives="representatives" />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .contact-table {
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
  }
  @media screen and (max-width: 769px) {
    .contact-table {
      grid-template-columns: auto;
      grid-template-rows: default;
    }
  }

  .contact-table > div {
    margin-bottom: 0.5rem;
    line-height: 1.25;
  }
</style>
