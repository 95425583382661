<template>
  <div v-if="notifications.length > 0" class="portal-notification woocommerce-notices-wrapper">
    <ul v-for="notification in notifications" :key="notification.message" role="alert" class="woocommerce-error">
      <li>
        <span style="margin-right: 0.5rem">{{ notification.message }}</span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
  import axios from 'axios';
  import { mapState } from 'pinia';

  import { useAccountStore } from '@/stores/account';

  export default {
    name: 'ExternalNotification',
    data() {
      return {
        notificationsURL: import.meta.env.VITE_APP_NOTIFICATIONS_URL,
        notifications: [] as {
          message: string;
        }[],
        lastUpdate: new Date().getTime() - 60 * 1000,
      };
    },
    computed: {
      ...mapState(useAccountStore, ['region']),
    },
    methods: {
      getNotifications(force?: boolean) {
        // If tab is active and notifications are older than 1 minute, fetch new notifications
        const now = new Date().getTime();
        if (force || (document.visibilityState === 'visible' && this.lastUpdate < now - 59 * 1000)) {
          this.lastUpdate = now;
          axios
            .get(`${this.notificationsURL}notifications/active`, {
              params: {
                region: this.region,
              },
            })
            .then((resp) => {
              this.notifications = resp.data ?? [];
            })
            .catch((error) => {
              console.error(error);
            });
        }
      },
    },
    mounted() {
      // Set interval to check for notifications every minute
      this.getNotifications();
      setInterval(this.getNotifications, 60 * 1000);
      document.addEventListener('visibilitychange', () => {
        this.getNotifications();
      });
    },
    watch: {
      region() {
        console.log('Region changed, fetching new notifications');
        this.getNotifications(true);
      },
    },
  };
</script>
