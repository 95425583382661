<template>
  <section class="section cart-dropdown is-flex flex-column">
    <div class="is-hidden-desktop checkout-dropdown__header section">
      <div class="container is-flex">
        <a @click.prevent="closeCart" class="has-text-dark menu-icon close" :aria-label="$t('generic.close_menu')">
          <span class="icon"><font-awesome-icon :icon="['far', 'times']" /></span>
        </a>
      </div>
    </div>
    <Notification
      :notifications="c_undo_notifications"
      :buttons="[
        { text: 'Ok', value: 'close' },
        { text: 'Ångra', value: 'undo' },
      ]"
      :detach="false"
      @button-clicked="p_c_notification_onClick"
      :source="'productlistmerged-cart'"
      class="mt-0"
    ></Notification>
    <section v-if="loading" class="section">
      <div class="has-text-centered">
        <p class="has-text-dark">
          <span class="is-size-6 has-text-weight-semibold">{{ $t('cart.loading.cart') }}</span>
        </p>
      </div>
    </section>
    <ProductList
      v-else-if="showCart"
      :columns="columns"
      :rows="list?.rows || []"
      @cell-event="emit"
      class="table-container pr-1 no-mobile-view"
      style="overflow: hidden scroll"
    >
      <template v-slot:column_row_action="{ row }">
        <RowActions :buttons="buttons(row)" @onRemoveFromCart="removeFromCart(row.id)" />
      </template>
    </ProductList>
    <section v-else class="section">
      <div class="has-text-centered">
        <p class="has-text-dark">
          <span class="is-size-6 has-text-weight-semibold">{{ $t('cart.no_items_in_cart') }}</span>
        </p>
      </div>
    </section>
    <section class="is-flex cart-options">
      <template v-if="showCart">
        <router-link
          class="button is-link is-uppercase has-text-weight-medium tracking-wide"
          style="height: fit-content"
          :to="{ name: 'Checkout' }"
        >
          {{ $t('cart.button.checkout') }}
        </router-link>
        <router-link
          v-if="checkoutV2Enabled"
          class="button is-link is-uppercase has-text-weight-medium tracking-wide"
          style="height: fit-content"
          :to="{ name: 'Checkout2' }"
        >
          Nya kassan
        </router-link>
      </template>
      <a
        v-else
        class="button is-link is-uppercase has-text-weight-medium tracking-wide"
        style="height: fit-content"
        disabled
      >
        {{ $t('cart.button.checkout') }}
      </a>
      <p class="has-text-dark padding-small">
        <span>
          <span class="is-size-7 is-size-8-mobile has-text-weight-semibold is-uppercase margin-xxsmall-right">
            {{ $t('order.sum_excl_vat') }}
          </span>
          <span v-if="p_c_total_price">
            {{ p_c_total_price.formatted.price }}
            <span class="is-size-8">{{ p_c_total_price.currency }}</span>
          </span>
        </span>
        <span>
          <span class="is-size-7 is-size-8-mobile has-text-weight-semibold is-uppercase margin-xxsmall-right">
            {{ $t('generic.weight') }}
          </span>
          <span>
            {{ p_c_total_weight.formatted }}
            <span class="is-size-8">{{ $t('unit.short.kg') }}</span>
          </span>
        </span>
        <span>
          <span class="is-size-7 is-size-8-mobile has-text-weight-semibold is-uppercase margin-xxsmall-right">
            {{ $t('generic.warehouse') }}
          </span>
          <span>{{ warehouse ? warehouse : '...' }}</span>
        </span>
      </p>
    </section>
  </section>
</template>

<script lang="ts">
  import type * as mixin from '@/types/ProductlistMixins';
  import { mapState, mapActions } from 'pinia';
  import _ from 'lodash';

  import { refreshCartInfo } from '@/data/api/kundportal/requests';
  import { articleBlocked1299, articleNumberOfDecimalPlaces } from '@/data/api/products/helpers';
  import { getArticleTitle } from '@/helpers';
  import { CartController } from '@/data/productlist';

  import ProductList from '@/components/productlist/ProductList.vue';
  import Notification from '@/components/Notification.vue';
  import RowActions from '@/components/RowActions.vue';
  import { useAccountStore } from '@/stores/account';
  import { useCartStore } from '@/stores/cart';
  import { useDebugStore } from '@/stores/debug';

  var Controller = new CartController();

  export default {
    name: 'CheckoutDropdown',
    components: {
      ProductList,
      Notification,
      RowActions,
    },
    mixins: Controller.mixins,
    data() {
      return {
        loading: true,
        columns: [
          {
            component: 'image',
            name: 'image',
            label: '',
            class: 'is-hidden-mobile',
          },
          {
            name: 'name_and_warehouse_status',
            label: this.$t('generic.designation'),
            class: 'item-long-description',
          },
          {
            name: 'unit_and_quantity',
            label: this.$t('generic.quantity'),
            class: 'minimalistic',
            style: 'width: 5rem',
          },
          {
            component: 'money',
            name: 'price',
            label: this.$t('generic.price'),
          },
          {
            component: 'row_action',
            name: 'row_action',
            label: '',
          },
        ],
        cart_hash: '',
      } as {
        loading: boolean;
        columns: any[];
        cart_hash: string;
      };
    },
    setup() {
      // Note: This is just to trick Typescript into understanding
      //       that these properties exist in the mixins
      return {} as mixin.ControllerMixin &
        mixin.DataStoreMixin &
        mixin.ArticleMixin &
        mixin.ArticleMergingMixin &
        mixin.PalletsMixin &
        mixin.PriceMixin &
        mixin.WarehouseMixin &
        mixin.CheckboxesMixin &
        mixin.TotalsMixin;
    },
    computed: {
      ...mapState(useAccountStore, ['status', 'user']),
      ...mapState(useCartStore, {
        info: 'info',
        cart: 'cart',
        total_item_count: 'total',
        warehouse: 'warehouseName',
        warehouseId: 'warehouseId',
        cartId: 'id',
      }),
      ...mapState(useDebugStore, ['debugMode']),
      checkoutV2Enabled() {
        return import.meta.env.VITE_APP_CHECKOUT_V2_ENABLED;
      },
      validInfo() {
        return this.info && this.info.warehouses;
      },
      showCart() {
        return this.validInfo && this.list?.rows?.length;
      },
      list() {
        if (!this.persistent?.list) return null;
        if (this.debugMode) console.debug('Re-rendering secondary layer(cart list)');

        // Convert rows into productlist format
        const list = {
          list: _.cloneDeep(this.persistent.list),
          rows: [] as any[],
        };

        if (_.isEmpty(this.p_c_rows)) return list;

        list.rows = this.p_c_rows.map((row) => {
          return {
            _animation: row.was_merged ? 'flash' : null,
            id: row.id,
            image: row._meta?.addon
              ? null
              : {
                  article: row.article,
                },
            name_and_warehouse_status: [
              {
                _meta: row._meta,
                component: 'name',
                name: 'name',
                label: getArticleTitle(row.article),
                article: row.article,
                batchnr: row.batchnr,
              },
              {
                _meta: row._meta,
                component: 'warehouse_status',
                name: 'warehouse_status',
                selectable: false,
                article: row.article,
                warehouse: this.warehouseId,
                customer_number: list.list.customer_number,
                amount: row.amount,
                unit: row.unit,
                batchnr: row.batchnr,
                type: 'cart',
              },
            ],
            unit_and_quantity: [
              {
                component: row._meta?.readonly ? 'string' : 'quantity',
                name: 'quantity',
                class: 'quantity',
                article: row.article,
                value: row._meta?.readonly ? `${row.amount} ${row.salesUnit || 'st'}` : row.amount,
                invalid: false,
                steppable: true,
                decimalPlaces: articleNumberOfDecimalPlaces(row.article, row.unit),
                readonly: row._meta?.readonly,
              },
              row._meta?.addon
                ? null
                : {
                    component: 'select',
                    name: 'unit',
                    width: '5.75rem',
                    class: 'is-size-7',
                    style: 'width: 6rem',
                    value: row.unit,
                    article: row.article,
                    options: row.article.unit,
                  },
            ].filter(Boolean),
            price: {
              component: row.needsQuote ? 'string' : null,
              value: row.needsQuote
                ? this.$t('price.price_offered')
                : articleBlocked1299(row.article)
                  ? '-'
                  : this.c_prices[row.id]?.price?.price_total,
              currency: `${this.c_prices[row.id]?.price?.currency ?? 'SEK'}`,
              class: 'is-size-7',
            },
            row_action: {
              is_addon: row._meta?.addon,
            },
          };
        });

        return list;
      },
    },
    methods: {
      ...mapActions(useAccountStore, ['login', 'logout']),
      buttons(row) {
        const r = row['row_action'];

        if (!r) return [];
        if (r.is_addon) return [];

        return [
          {
            name: 'remove',
            icon: ['far', 'times'],
            class: 'icon is-small is-right add-row-btn is-danger p-3',
            onClick: 'onRemoveFromCart',
          },
        ];
      },
      closeCart() {
        this.$emit('close');
      },
      removeFromCart(id) {
        this.emit({
          name: 'store:remove-from-list',
          value: [id],
        });
      },
      // updateCartRowWarehouses(data: any, warehouseId: string) {
      //   if (!data || !data.list || !data.rows) return;

      //   // Update each row with the correct warehouse
      //   data.rows.forEach((row) => {
      //     this.emit({
      //       row: row.id,
      //       name: 'update',
      //       key: 'warehouse',
      //       value: warehouseId,
      //     });
      //   });
      // },
      fetchCart(forcedFetch = false) {
        const storedCartIsEmpty = _.isEmpty(this.cart);
        if (!storedCartIsEmpty) {
          Controller.set(this, undefined, this.cart);
          this.p_c_selected_warehouse = this.warehouseId;
          this['loading'] = false;
        }
        if (forcedFetch || storedCartIsEmpty) {
          Controller.get(this, this.cartId).then((data) => {
            this['loading'] = false;
            if (!data) return;

            this.p_c_selected_warehouse = data.list.warehouse;
          });
        }
      },
      onFocus() {
        window.removeEventListener('focus', this.onFocus);
        this.fetchCart();
      },
    },
    watch: {
      user: {
        immediate: true,
        handler() {
          if (!this.user || !this.user?.user) {
            return;
          }
          if (this.debugMode) console.debug('User changed, fetching cart', this.user);
          if (this.user?.isBendersEmployee) this.c_activePrice = 'netPrice';
          refreshCartInfo();
        },
      },
      info: {
        immediate: true,
        handler() {
          if (!this.info?.id) {
            return;
          }
          Controller.init(); // Make sure we have a clean slate

          this.fetchCart(true);
        },
      },
      cart: {
        immediate: true,
        deep: true,
        handler() {
          // If window is not in focus, then wait until it gets focued again before running 'fetchCart'
          if (!document.hasFocus()) {
            window.addEventListener('focus', this.onFocus);
          } else {
            this.fetchCart();
          }
        },
      },
      // warehouseId: {
      //   immediate: true,
      //   handler() {
      //     if (!this.warehouseId) {
      //       return;
      //     }
      //     this.updateCartRowWarehouses(this.persistent, this.warehouseId);
      //   },
      // },
    },
  };
</script>
