<script setup lang="ts">
  import { computed } from 'vue';

  import NoteFull from '@/assets/icons/note-full.svg?component';
  import NoteEmpty from '@/assets/icons/note-empty.svg?component';

  const prop = defineProps<{
    icon: string;
  }>();

  const iconComponent = computed(() => {
    switch (prop.icon) {
      case 'note-full':
        return NoteFull;
      case 'note-empty':
        return NoteEmpty;
      default:
        return null;
    }
  });
</script>

<template>
  <component
    :is="iconComponent"
    class="svg-inline--fa"
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    role="img"
  ></component>
</template>
