import ls from '@livesession/sdk';

export const LiveSession = ls;

// // Openreplay
// import Tracker from '@openreplay/tracker';
// import trackerAssist from '@openreplay/tracker-assist';
// import store from '@/store'

// const or = new Tracker({
//   projectKey: "C4yaoourO3LQWhMJ8KX2",
//   ingestPoint: "https://sessionreplay.duva.se/ingest",
//   onStart: () => {
//         if(!store.getters['account/userId']) return
//         or.setUserID(store.getters['account/userId']);
//         console.log('OpenReplay assigning user:', store.getters['account/userId'])
//     },
//   __DISABLE_SECURE_MODE: import.meta.env.VITE_APP_ENV === 'development',
// });
// or.use(trackerAssist())

// export const OpenReplay = or
