<template>
  <div>
    <div @click="closeOnBackgroundClick && $emit('close')" :class="['popup__backdrop', visible ? 'open' : null]"></div>
    <div
      ref="popup"
      :class="['popup', 'is-size-6', visible ? 'open' : null, slim ? 'popup--slim' : null]"
      :style="popupStyle"
    >
      <button @click="$emit('close')" class="popup__close unstyled-button" :aria-label="$t('generic.close')">
        <span class="is-large icon"><font-awesome-icon size="lg" :icon="['far', 'times']" /></span>
      </button>
      <div class="columns">
        <div class="column">
          <slot name="title" />
          <slot name="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'PopupGeneric',
    data() {
      return {};
    },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      slim: Boolean,
      popupStyle: {
        type: String,
        default: '',
      },
      closeOnBackgroundClick: {
        type: Boolean,
        default: true,
      },
    },
    watch: {
      visible() {
        if (!this.visible) {
          return;
        }
        this.$nextTick(() => {
          (this.$refs.popup as HTMLElement).scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
        });
      },
    },
    computed: {},
    methods: {},
    mounted() {},
  };
</script>
