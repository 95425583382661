<template>
  <div class="columns" v-if="current !== null">
    <div class="column is-4">
      <figure class="image is-square" v-html="current.thumbnail_html"></figure>
    </div>
    <div class="column is-8">
      <template v-if="(representatives?.length ?? 0) > 1">
        <h1 class="title is-4 margin-xxsmall-bottom">{{ $t('representative.other_questions') }}</h1>
        <div class="control margin-xxxsmall-bottom">
          <div class="select is-full-width">
            <select class="is-full-width" v-model="current">
              <option v-for="option in optionsByCustomer" :key="option.title" :value="option.value">
                {{ option.title }}
              </option>
            </select>
          </div>
        </div>
      </template>
      <template v-else>
        <h1 class="title is-4">{{ $t('representative.other_questions') }}</h1>
      </template>
      <p>
        {{ current.name }}
        <br />
        <template v-if="!empty(current.phone)">
          {{ $t('generic.phone_short') }}: {{ current.phone }}
          <br />
        </template>
        <template v-if="!empty(current.sms_and_mms)">
          {{ $t('generic.sms/mms') }}: {{ current.sms_and_mms }}
          <br />
        </template>
        <template v-if="!empty(current.email)">
          {{ $t('generic.email') }}:
          <a :href="`mailto:${current.email}`">{{ current.email }}</a>
          <br />
        </template>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { PropType } from 'vue';

  export default {
    name: 'RepresentativeViewer',
    props: {
      representatives: Array as PropType<any[]>,
    },
    data() {
      return {
        current: this.representatives?.length ? this.representatives[0] : null,
      };
    },
    computed: {
      optionsByCustomer() {
        let options = [] as {
          title: string;
          value: any;
        }[];

        this.representatives?.forEach((representative) => {
          options = options.concat(
            representative.customers.map((customer) => ({
              title: `${customer.companyName} (${customer.id})`,
              value: representative,
            }))
          );
        });

        return options;
      },
    },
    watch: {
      representatives() {
        if (this.current === null) {
          this.current = this.representatives?.length ? this.representatives[0] : null;
        }
      },
    },
    methods: {
      empty(value) {
        return value === null || value === undefined || value === false || value === '';
      },
    },
  };
</script>
