<template>
  <div>
    <div class="field">
      <label class="label is-uppercase is-size-7">{{ $t('generic.corporation') }}</label>
      <div class="control">
        <DynamicSelector
          ref="selector_corporation"
          :runOnMount="run_on_mount"
          type="corporation"
          :initialOptions="initialOptions.corporation"
          v-model="corporation"
          current=""
          :filters="{}"
          :extendedValues="true"
        />
      </div>
    </div>

    <div class="field">
      <label class="label is-uppercase is-size-7">{{ $t('generic.representative') }}</label>
      <div class="control">
        <DynamicSelector
          ref="selector_representative"
          :runOnMount="run_on_mount"
          type="representative"
          :initialOptions="initialOptions.representative"
          v-model="representative"
          current=""
          currentText="Alla"
          :filters="{ corporation }"
          :showSelectionAll="true"
          :extendedValues="true"
        />
      </div>
    </div>

    <div class="field">
      <label class="label is-uppercase is-size-7">{{ $t('generic.customer') }}</label>
      <div class="control">
        <DynamicSelector
          ref="selector_customer"
          :runOnMount="run_on_mount"
          type="customer"
          :initialOptions="initialOptions.customer"
          v-model="customer"
          current=""
          currentText="Skriv kundnummer eller kundens namn"
          :disableEmptySearch="true"
          :filters="{ corporation, representative, amount: 10 }"
          :showSelectionAll="true"
          :extendedValues="true"
        />
      </div>
    </div>
    <button
      @click.prevent="submitFilter"
      :disabled="!filterHasChange"
      :class="[
        'button',
        'is-link',
        'is-size-7',
        'is-uppercase',
        'has-text-weight-medium',
        'tracking-wide',
        'margin-xxxsmall-top',
        is_mobile ? 'is-fullwidth' : null,
      ]"
    >
      {{ $t('generic.view') }}
    </button>

    <form method="post" style="margin-top: 1rem" @submit.prevent="actAsSubmit">
      <input type="hidden" name="redirect_to" :value="post_redirect" />
      <input type="hidden" name="benders_kundportal_action" value="act_as_user" />
      <div class="field">
        <label class="label is-uppercase is-size-7">{{ $t('generic.users') }}</label>
        <div class="control">
          <DynamicSelector
            ref="selector_user"
            :runOnMount="run_on_mount"
            type="user"
            v-model="user"
            current=""
            currentText=""
            :filters="{ corporation: corporation, representative: representative, customer: customer }"
          />
        </div>
      </div>
      <button
        :class="[
          'button',
          'is-link',
          'is-size-7',
          'is-uppercase',
          'has-text-weight-medium',
          'tracking-wide',
          'margin-xxxsmall-top',
          is_mobile ? 'is-fullwidth' : null,
        ]"
        :disabled="loading.actAs || !user"
        v-button-loader:dot-collision="loading.actAs"
      >
        {{ $t('generic.act_as') }}
      </button>
    </form>
  </div>
</template>

<script lang="ts">
  import { useAccountStore } from '@/stores/account';
  import DynamicSelector from './DynamicSelector.vue';
  import { mapActions, mapState } from 'pinia';
  import { useHistoryStore } from '@/stores/history';
  import { PropType } from 'vue';

  export default {
    name: 'TripleSelector',
    components: {
      DynamicSelector,
    },
    props: {
      post_redirect: String as PropType<string>,
      defaults: {
        type: Object as PropType<Record<string, string>>,
        default: () => ({}),
      },
      is_mobile: Boolean as PropType<boolean>,
      run_on_mount: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },
    data() {
      return {
        selected: {
          corporation: '',
          representative: '',
          customer: '',
          user: '',
        },
        loading: {
          actAs: false,
        },
      };
    },
    computed: {
      ...mapState(useAccountStore, ['filter']),
      corporation: {
        get() {
          return this.selected.corporation;
        },
        set(value) {
          this.resetSelectionsByType('corporation');
          this.selected.corporation = value;
        },
      },
      representative: {
        get() {
          return this.selected.representative;
        },
        set(value) {
          this.resetSelectionsByType('representative');
          this.selected.representative = value;
        },
      },
      customer: {
        get() {
          return this.selected.customer;
        },
        set(value) {
          this.resetSelectionsByType('customer');
          this.selected.customer = value;
        },
      },
      user: {
        get() {
          return this.selected.user;
        },
        set(value) {
          this.selected.user = value;
        },
      },
      initialOptions() {
        let object = {} as {
          corporation: any;
          representative: any;
          customer: any;
        };
        for (let key in this.filter) {
          if (!this.filter[key]) continue;

          let values = this.filter[key].split('|');
          object[key] = [{ value: this.filter[key], text: values[1] }];
        }
        return object;
      },
      filterHasChange() {
        for (let key in this.filter) {
          if (this[key] !== this.filter[key]) return true;
        }
        return false;
      },
    },
    methods: {
      ...mapActions(useAccountStore, ['actAs', 'updateFilter']),
      ...mapActions(useHistoryStore, ['reload']),
      resetSelectionsByType(type) {
        switch (type) {
          case 'corporation':
            this.representative = '';
          // falls through
          case 'representative':
            this.customer = '';
          // falls through
          case 'customer':
            this.user = '';
          // falls through
        }
      },
      getRedirectTo() {
        if (window.location.search && window.location.href !== this.post_redirect) return window.location.href;
        return this.post_redirect;
      },
      actAsSubmit() {
        this.loading.actAs = true;
        this.$nextTick(() => {
          this.actAs(this.user).catch((e) => {
            console.error(e);
            this.loading.actAs = false;
          });
          this.$emit('close');
        });
      },
      submitFilter() {
        this.updateFilter({
          corporation: this.corporation,
          representative: this.representative,
          customer: this.customer,
        });
        this.$nextTick(() => {
          this.$emit('close');
          // this.reload()
        });
      },
    },
    mounted() {
      if (this.filter) {
        this.corporation = this.filter.corporation;
        this.representative = this.filter.representative;
        this.customer = this.filter.customer;
      }
    },
    watch: {
      // corporation () {
      //   this.submitFilter()
      // },
      // representative () {
      //   this.submitFilter()
      // },
      // customer () {
      //   this.submitFilter()
      // },
    },
  };
</script>
