import { defineStore } from 'pinia';

import { useStorage } from '@vueuse/core';

const jsonSerializer = {
  read: (v: any) => (v ? JSON.parse(v) : null),
  write: (v: any) => JSON.stringify(v),
};

export const useSessionStore = defineStore('session', {
  state: () => ({
    checkout: useStorage('checkout', {}, sessionStorage, {
      serializer: jsonSerializer,
    }),
  }),
  actions: {
    // Overwrites the entire states key with the new value
    set({ key, value }: { key: string; value: any }) {
      if (!key) return;
      if (this.$state[key]?._disabled) return;
      this.$state[key] = value;
    },
    // Updates the state key with the new value
    update({ key, value }: { key: string; value: any }) {
      if (!key) return;
      if (this.$state[key]?._disabled) return;
      this.$state[key] = { ...this.$state[key], ...value };
    },
    // Removes the state key
    remove({ key }: { key: string }) {
      if (!key) return;
      if (this.$state[key]?._disabled) return;
      delete this.$state[key];
    },
    // Empties the state key
    clear(key: string) {
      if (!key) return;
      if (this.$state[key]?._disabled) return;
      this.$state[key] = {};
    },
    // Empties the entire state
    clearSession() {
      for (let key in this.$state) {
        if (this.$state[key]?._disabled) continue;
        this.$state[key] = {};
      }
    },
    // Disables the state key
    disable(key: string) {
      if (!key) return;
      this.$state[key] = { _disabled: true };
    },
    // Enables the state key
    enable(key: string) {
      if (!key) return;
      if (this.$state[key]?._disabled) this.$state[key] = {};
    },
  },
});
