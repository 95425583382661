import 'intersection-observer';
import 'element-closest-polyfill';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

// require('intersection-observer');
// require('element-closest-polyfill');
// require('abortcontroller-polyfill/dist/polyfill-patch-fetch');

// CustomEvent ( https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent )
(function () {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event: string, params?: { bubbles?: boolean; cancelable?: boolean; detail?: any }) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles || false, params.cancelable || false, params.detail || false);
    return evt;
  }

  // Declare the CustomEvent type on the Window interface
  interface Window {
    CustomEvent: typeof CustomEvent;
  }

  (window as unknown as Window).CustomEvent = CustomEvent;
})();

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith#Polyfill
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (search, this_len) {
    if (this_len === undefined || this_len > this.length) {
      this_len = this.length;
    }
    return this.substring(this_len - search.length, this_len) === search;
  };
}
