// import _ from 'lodash'
import type { Customer, PostOrder, Representative } from '@/types/Checkout';

import { nanoid } from 'nanoid';
import { useCartStore } from '@/stores/cart';
import { useAccountStore } from '@/stores/account';

import { instance, cancel } from '@/data/api/kundportal/axios';
import { instance as productapiInstance } from '@/data/api/products/axios';

export function findUsers(this: any, query, filters = {}) {
  const _uid = this?._uid ?? '';
  cancel[`user${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get('users', {
        params: { s: query, f: encodeURI(JSON.stringify(filters)) },
        signal: cancel[`user${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        let re = new RegExp(`${query}`, 'ig');
        let reString = query ? '<b>$&</b>' : '';
        resolve(
          [...r.data.data].map((row) => ({
            text: row.email,
            html: `${row.email}`.replace(re, reString),
            value: row.email,
          }))
        );
      });
  });
}

export function findContacts(query) {
  return new Promise((resolve, reject) => {
    instance
      .get('contacts', {
        params: { s: query },
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(
          [...r.data.data].map((row) => ({
            text: `${row.fullName} (${row.customerNumber ? row.customerNumber : '---'})`,
            value: row.id,
          }))
        );
      });
  });
}

export function findCustomers(this: any, query, filters = null, extended) {
  const _uid = this?._uid ?? '';
  cancel[`customer${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get('customers', {
        params: { s: query, f: filters ? encodeURI(JSON.stringify(filters)) : null },
        signal: cancel[`customer${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        let re = new RegExp(`${query}`, 'ig');
        let reString = query ? '<b>$&</b>' : '';
        resolve(
          [...r.data.data].map((row) => ({
            text: `${row.companyName} (${row.id})`,
            html: `${row.companyName} (${row.id})`.replace(re, reString),
            value: extended ? `${row.id}|${row.companyName} (${row.id})` : row.id,
          }))
        );
      });
  });
}

export function findRoles(query) {
  return new Promise((resolve, reject) => {
    instance
      .get('roles', {
        params: { s: query },
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(
          [...r.data.data].map((row) => ({
            text: row.displayName,
            value: row.id,
          }))
        );
      });
  });
}

export function findCorporations(this: any, query, _filters, extended) {
  const _uid = this?._uid ?? '';
  cancel[`corporation${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get('corporations', {
        params: { s: query },
        signal: cancel[`corporation${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        let re = new RegExp(`${query}`, 'ig');
        let reString = query ? '<b>$&</b>' : '';
        resolve(
          [...r.data.data].map((row) => ({
            text: row.name,
            html: `${row.name}`.replace(re, reString),
            value: extended ? `${row.value}|${row.name}` : row.value,
          }))
        );
      });
  });
}

export function findRepresentatives(this: any, query, filters: any = null, extended) {
  const _uid = this?._uid ?? '';
  cancel[`representative${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get('representatives', {
        params: { s: query, f: filters ? encodeURI(JSON.stringify(filters)) : null },
        signal: cancel[`representative${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        let re = new RegExp(`${query}`, 'ig');
        let reString = query ? '<b>$&</b>' : '';
        resolve(
          [...r.data.data].map((row) => ({
            name: row.post_title,
            text: `${row.post_title} (${row.salespersonId})`,
            html: `${row.post_title} (${row.salespersonId})`.replace(re, reString),
            value: extended ? `${row.salespersonId}|${row.post_title} (${row.salespersonId})` : row.salespersonId,
          }))
        );
      });
  });
}

export function defaultRepresentativeFilter(this: any): any {
  const _uid = this?._uid ?? '';
  cancel[`representative${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get('default_representative_filter', {
        params: {},
        signal: cancel[`representative${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data.data);
      });
  });
}

// Shopping list functions
export function getCartInfo() {
  return new Promise((resolve, reject) => {
    instance
      .get(`cart_info`, {})
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function refreshCartInfo() {
  return new Promise((resolve) => {
    const { getInfo } = useCartStore();
    getInfo(true).then((r) => {
      resolve(r);
    });
  });
  // return getCartInfo().then((r) => {
  //     store.commit("cart/info", r?.data)
  // })
}

function generateMissingListData(listId, data) {
  // Add missing row info such as temporary id
  data = data.reduce((arr, row) => {
    // row.id = row.id || uniqid(`${row.article_id}${listId}`);
    row.id = row.id || nanoid();
    row.list_id = listId;
    arr.push(row);
    return arr;
  }, []);
  return data;
}

export function createList(list_name, customer_number) {
  return new Promise((resolve, reject) => {
    instance
      .post('list/create', {
        list_name: list_name,
        customer_number: customer_number,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function removeList(id) {
  return new Promise((resolve, reject) => {
    instance
      .delete(`list/delete/${id}`, {})
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function updateList(id, data, triggerCartUpdate) {
  if (triggerCartUpdate) {
    data = generateMissingListData(id, data);
  }

  return new Promise((resolve, reject) => {
    instance
      .post(`list/update/${id}`, data)
      .catch((e) => {
        reject(e);
        if (triggerCartUpdate) {
          const { setCart } = useCartStore();
          setCart({} as any);
        }
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          if (triggerCartUpdate) {
            const { setCart } = useCartStore();
            setCart({} as any);
          }
          return;
        }

        resolve(r.data);
      });
  });
}

export function addToList(id, data, triggerCartUpdate?: boolean) {
  if (triggerCartUpdate) {
    data = generateMissingListData(id, Array.isArray(data) ? data : [data]);
    const { setWarehouse, addItems } = useCartStore();
    if (data.length > 0) setWarehouse(data[0].warehouse);

    addItems(data);
  }

  return new Promise((resolve, reject) => {
    instance
      .post(`list/add/${id}`, data)
      .catch((e) => {
        if (triggerCartUpdate) {
          const { setCart } = useCartStore();
          setCart({} as any);
        }
        reject(e);
      })
      .then((r) => {
        if (!r) {
          if (triggerCartUpdate) {
            const { setCart } = useCartStore();
            setCart({} as any);
          }
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function setListName(listId, name) {
  return new Promise((resolve, reject) => {
    if (!listId || !name) {
      reject('Missing list id or name');
      return;
    }
    instance
      .post(`list/setName/${listId}`, { name: name })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function setListWarehouse(listId, warehouseId) {
  return new Promise((resolve, reject) => {
    if (!listId || !warehouseId) {
      reject('Missing list id or warehouse id');
      return;
    }
    instance
      .post(`list/setWarehouse/${listId}`, { warehouseId: warehouseId })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function getLists(id = '', params) {
  return new Promise((resolve, reject) => {
    instance
      .get(`list/lists/${id}`, {
        params: params,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function getCheckout() {
  return new Promise((resolve, reject) => {
    instance
      .get('list/cart', {
        params: {},
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data.data);
        // updateCart(id, r.data.data.list.rows, null)
      });
  });
}

export function createOrder(data: PostOrder) {
  return new Promise((resolve, reject) => {
    // If the data has an ordernumber, it should be sent directly to M3
    const orderNumber = data.orderNumber;
    const route = orderNumber
      ? productapiInstance.put(`orders/update/${orderNumber}`, data)
      : instance.post('createorder', data);
    route
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function getOrderNumber(guid) {
  return new Promise((resolve, reject) => {
    instance
      .get(`ordernumber`, {
        params: { guid: guid },
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function sendPickupNotification(data) {
  var formData = new URLSearchParams();
  for (let key in data) formData.append(key, data[key]);
  return new Promise((resolve, reject) => {
    instance
      .post(`pickupNotification`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function createTicket(data) {
  const getFormData = (data) => {
    var formData = new FormData();

    const getKeyValues = (data, path = '') => {
      const result = new Map();

      for (let key in data) {
        const pos = path ? `${path}.${key}` : key;
        if (Array.isArray(data[key])) {
          let isFileArray = data[key].every((file) => file instanceof File);
          if (isFileArray) {
            data[key].forEach((file) => result.set(`${pos}[]`, file));
            continue;
          }
        } else if (typeof data[key] === 'object') {
          const nested = getKeyValues(data[key], pos);
          for (let [key, value] of nested) {
            result.set(key, value);
          }
          continue;
        }

        result.set(pos, data[key]);
      }

      return result;
    };

    const result = getKeyValues(data);

    for (let [key, value] of result) {
      formData.append(key, value);
    }

    return formData;
  };

  const formData = getFormData(data);
  return new Promise((resolve, reject) => {
    instance
      .post(`createticket`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function getUserLogo(raw = false) {
  return new Promise((resolve, reject) => {
    instance
      .get(`userlogo`, {
        params: { raw: raw ? '1' : '' },
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function uploadUserLogo(data) {
  var formData = new FormData();
  for (let key in data) formData.append(key, data[key]);
  return new Promise((resolve, reject) => {
    instance
      .post(`userlogo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function deleteUserLogo() {
  return new Promise((resolve, reject) => {
    instance
      .delete(`userlogo`)
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function createContact(data, params?: any) {
  var formData = new FormData();
  for (let key in data) formData.append(key, data[key]);
  return new Promise((resolve, reject) => {
    instance
      .post(`contacts/create`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function updateContact(id, data, params?: any) {
  var formData = new FormData();
  for (let key in data) formData.append(key, data[key]);
  return new Promise((resolve, reject) => {
    instance
      .post(`contacts/update/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function deleteContact(id, data?: any) {
  var formData = new FormData();
  for (let key in data) formData.append(key, data[key]);
  return new Promise((resolve, reject) => {
    instance
      .post(`contacts/delete/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export type Profile = {
  email: string;
  defaultCNumber: string;
  showGrossPrices: string;
  showContractPrices: string;
  warehouseLogic: string;
  warehouses: { [key: string]: string };
  defaultStockAddress: string;
};

export function getProfile(): Promise<Profile> {
  return new Promise((resolve, reject) => {
    instance
      .get(`profile`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data.data as Profile);
      });
  });
}

export function setProfile(data) {
  var formData = new FormData();
  for (let key in data) formData.append(key, data[key]);
  return new Promise((resolve, reject) => {
    instance
      .post(`profile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }

        resolve(r.data);
      });
  });
}

export function getCustomerContacts(this: any, customerNumber) {
  const _uid = this?._uid ?? '';
  cancel[`getcustomercontacts${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get('customer_contacts/' + customerNumber, {
        params: { posts_per_page: -1 },
        signal: cancel[`getcustomercontacts${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data.data);
      });
  });
}

export function getCustomer(this: any, customerNumber): Promise<Customer> {
  if (!customerNumber) return Promise.reject('No customer number');

  const _uid = this?._uid ?? '';
  cancel[`getcustomer${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get('customer/' + customerNumber, {
        params: {},
        signal: cancel[`getcustomer${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data.data);
      });
  });
}

export function getCustomerAddresses(this: any, customerNumber, type = '') {
  const _uid = this?._uid ?? '';
  cancel[`addresses${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get('customerAddresses/' + customerNumber + '/' + type, {
        params: {},
        signal: cancel[`addresses${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data.data);
      });
  });
}

export function getArticles(this: any, category = '', page = 1, filters = {} as any, method = 'GET') {
  const _uid = this?._uid ?? '';

  // Add additional filters for factory unique articles
  filters.aggs.article_count = {
    cardinality: {
      field: 'ArtFamily.raw',
    },
  };
  filters.collapse = {
    field: 'ArtFamily.raw',
    inner_hits: {
      name: 'collapsed',
      size: 20,
      sort: {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: "if (doc.containsKey('master.raw') && doc['master.raw'].empty) { return 1 } else { return 0 }",
            params: {
              factor: 1.1,
            },
          },
          order: 'desc',
        },
      },
    },
  };

  return new Promise((resolve, reject) => {
    let params = {
      page: page,
      filters: filters ? (method === 'GET' ? encodeURI(JSON.stringify(filters)) : filters) : null,
    };

    cancel[`articles${_uid}`] = new AbortController();
    instance
      .request({
        url: `articles/${category}`,
        method: method,
        ...(method === 'GET' ? { params: params } : { data: params }),
        signal: cancel[`articles${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data.data);
      });
  });
}

export function getOrders(this: any, id = '', params = {}) {
  const _uid = this?._uid ?? '';
  cancel[`orders${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get(`orders/${id}`, {
        params: params,
        signal: cancel[`orders${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getQuotes(this: any, id = '', params = {}) {
  const _uid = this?._uid ?? '';
  cancel[`quotes${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get(`offers/${id}`, {
        params: params,
        signal: cancel[`quotes${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function saveQuote(id = '', data, params = {}) {
  return new Promise((resolve, reject) => {
    instance
      .post(`offers/${id}`, data, params)
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function deleteQuote(id) {
  return new Promise((resolve, reject) => {
    if (!id) {
      reject();
      return;
    }
    instance
      .delete(`offers/${id}`)
      .catch((e) => reject(e))
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getTickets(this: any, id = '', params = {}) {
  const _uid = this?._uid ?? '';
  cancel[`tickets${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get(`tickets/${id}`, {
        params: params,
        signal: cancel[`tickets${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getPricelists(id = '', params = {}) {
  return new Promise((resolve, reject) => {
    instance
      .get(`pricelists/${id}`, {
        params: params,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getAddresses(this: any, id = '', params = {}) {
  const _uid = this?._uid ?? '';
  cancel[`addresses${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get(`addresses/${id}`, {
        params: params,
        signal: cancel[`addresses${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getUserContacts(this: any, id = '', params = {}) {
  const _uid = this?._uid ?? '';
  cancel[`useContacts${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .get(`userContacts/${id}`, {
        params: params,
        signal: cancel[`useContacts${_uid}`].signal,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getAccountContacts(params = {}) {
  return new Promise((resolve, reject) => {
    instance
      .get(`get_account_contacts`, {
        params: params,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getCurrentUserCustomers(params = {}) {
  return new Promise((resolve, reject) => {
    instance
      .get(`current_user_customers`, {
        params: params,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data.data);
      });
  });
}

export function getProductCategories(params = {}) {
  return new Promise((resolve, reject) => {
    instance
      .get(`productCategories`, {
        params: params,
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getWarehouses(category = '', filter = '', region = '') {
  return new Promise((resolve, reject) => {
    instance
      .get(`warehouses/${category}`, {
        params: {
          filter,
          region,
          nocache: true,
        },
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getWpWarehouses() {
  return new Promise((resolve, reject) => {
    instance
      .get(`wp_warehouses`, {})
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getGroupedRepresentatives(
  includeOther = false,
  pricelisttable = ''
): Promise<Record<string, Record<string, Representative>>> {
  return new Promise((resolve, reject) => {
    instance
      .get(`grouped_representatives`, {
        params: {
          include_other: includeOther ? 'true' : 'false',
          pricelisttable: pricelisttable,
        },
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getUserRepresentatives(user_id): any {
  return new Promise((resolve, reject) => {
    instance
      .get(`user_representatives/${encodeURIComponent(user_id)}`, {})
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function submitAccountForm(data) {
  var formData = new URLSearchParams();
  for (let key in data) formData.append(key, data[key]);
  return new Promise((resolve, reject) => {
    instance
      .post(`account_request`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function resetPassword(data: { email?: string; password?: string; nonce?: string }) {
  var formData = new URLSearchParams();
  for (let key in data) formData.append(key, data[key]);
  return new Promise((resolve, reject) => {
    instance
      .post(`resetpassword`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getWpPage(slug: string = '') {
  return new Promise((resolve, reject) => {
    // axios.get(`${import.meta.env.VITE_APP_WP_API_BASE_URL}wp/v2/pages?slug=/${slug}`)
    instance
      .get(`${import.meta.env.VITE_APP_WP_API_BASE_URL}benders/v1/page/${slug}`)
      .catch((e) => {
        reject(e);
      })
      .then((r: any) => {
        const data = r?.data?.data;
        if (!(data && data.content && data.title)) {
          reject('Empty response/no data');
          return;
        }
        resolve({
          title: data.title,
          content: data.content,
        });
      });
  });
}

export function getDebugEntity(entity, id) {
  return new Promise((resolve, reject) => {
    instance
      .get(`debug_entity/${entity}/${id}`)
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          reject('Empty response/no data');
          return;
        }
        resolve(r.data?.data);
      });
  });
}

export function cancelRequest(this: any, type = '', reason = 'canceled') {
  const _uid = this?._uid ?? '';
  let controller = cancel[`${type}${_uid}`] || cancel[type];
  if (Array.isArray(controller)) {
    for (let i = 0; i < controller.length; i++) controller[i].abort(reason);
  } else if (controller) {
    controller.abort(reason);
  }
}

// Debugging
const createSentryWarning = (msg, obj) => {
  if (window.Sentry) {
    window.Sentry.withScope(function (scope) {
      scope.setLevel('warn');
      window.Sentry.captureException(new Error(msg + ': ' + JSON.stringify(obj)));
    });
  }

  /* eslint-enable no-unused-vars */
};

export function validateAddressForCustomer(address, customerNumber) {
  if (!address || !customerNumber || address == '#00001') return Promise.resolve(true);

  return instance
    .get(`/validateAddress/${customerNumber}/${address}`)
    .then((response) => {
      if (!response?.data?.success) {
        // Trigger sentry exception
        createSentryWarning('Validation failed', {
          address,
          customerNumber,
          response,
        });
      }

      return response.data?.success;
    })
    .catch((error) => {
      // Trigger sentry exception
      createSentryWarning('Validation failed', {
        address,
        customerNumber,
        error,
      });
      return false;
    });
}

export function getDefaultUserRegion() {
  return new Promise((resolve, reject) => {
    instance
      .get(`default_user_region`)
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          console.error('Empty response/no data for defaultuserregion', r);
          reject('Empty response/no data');
          return;
        }
        resolve(r.data);
      });
  });
}

export function getTicketOptions() {
  const { region } = useAccountStore();

  if (!region) return Promise.resolve([]);

  return new Promise((resolve, reject) => {
    instance
      .get(`ticket_options/${region}`)
      .catch((e) => {
        reject(e);
      })
      .then((r) => {
        if (!r) {
          console.error('Empty response/no data for ticket_options', r);
          reject('Empty response/no data');
          return;
        }
        resolve(r.data?.data);
      });
  });
}
