<template>
  <footer class="footer">
    <a class="back-to-top" href="#">
      <span class="back-to-top__content flexed flex-column justify-center items-center">
        <span class="icon is-small is-block back-to-top__icon">
          <font-awesome-icon :icon="['far', 'chevron-up']" />
        </span>
        {{ $t('footer.back_to_top') }}
      </span>
    </a>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-2-desktop is-12-mobile">
            <h3 class="is-size-7 leading-loose is-uppercase has-text-weight-bold has-text-white">
              {{ $t('footer.support') }}
            </h3>
            <ul>
              <li>
                <router-link class="has-text-light" to="/calculation/wall">
                  {{ $t('generic.calculation_program') }}
                </router-link>
              </li>
              <li>
                <router-link class="has-text-light" to="/warehouse/arboga/">
                  {{ $t('warehouse.our_warehouses') }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="column is-8-desktop is-12-mobile">
            <template v-if="region == 'SAN'">
              <h3 class="is-size-7 leading-loose is-uppercase has-text-weight-bold has-text-white">
                {{ $t('footer.contact') }}
              </h3>
              <p v-if="region == 'SAN'" class="has-text-light">
                {{ $t('generic.phone') }}: 33 45 63 50
                <br />
                {{ $t('generic.email') }}:
                <a class="has-text-light" href="mailto:post@benders.no">post@benders.no</a>
              </p>
              <h4 class="is-size-7 leading-loose is-uppercase has-text-weight-bold has-text-white margin-xxlarge-top">
                Benders Norge AS
              </h4>
              <p class="has-text-light">
                Pindsleveien 7
                <br />
                3221 Sandefjord
              </p>
            </template>
            <template v-else>
              <h3 class="is-size-7 leading-loose is-uppercase has-text-weight-bold has-text-white">
                {{ $t('footer.contact') }}
              </h3>
              <p class="has-text-light">
                {{ $t('generic.phone') }}: 010-888 00 00
                <br />
                {{ $t('generic.email') }}:
                <a class="has-text-light" href="mailto:order@benders.se">order@benders.se</a>
                ,
                <a class="has-text-light" href="mailto:offert@benders.se">offert@benders.se</a>
                ,
                <a class="has-text-light" href="mailto:backoffice@benders.se">backoffice@benders.se</a>
                ,
                <a class="has-text-light" href="mailto:teknisksupport@benders.se">teknisksupport@benders.se</a>
              </p>
              <h4 class="is-size-7 leading-loose is-uppercase has-text-weight-bold has-text-white margin-xxlarge-top">
                Benders Sverige AB
              </h4>
              <p class="has-text-light">
                Box 20
                <br />
                535 21 Kvänum
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <section class="section legal">
      <div class="container flexed justify-between items-end" style="flex-wrap: wrap">
        <div class="footer-mobile">
          <p class="has-text-light is-size-7">
            <template v-if="region == 'SAN'">© 2019 Benders Norge AS</template>
            <template v-else>© 2019 Benders Sverige AB</template>
            <router-link class="has-text-light margin-xxsmall-left" :to="'/wp-page/' + divisionLinks.termsAndAgreement">
              {{ $t('generic.terms_and_agreements') }}
            </router-link>
            <router-link class="has-text-light margin-xxsmall-left" :to="'/wp-page/' + divisionLinks.privacyPolicy">
              {{ $t('generic.privacy_policy') }}
            </router-link>
            <router-link
              v-if="status.PRODUCT_API_ENABLE"
              class="has-text-light margin-xxsmall-left"
              :to="'/wp-page/' + divisionLinks.salesDeliveryPolicy"
            >
              {{ $t('generic.sales_delivery_policy') }}
            </router-link>
          </p>
          <p class="has-text-light is-size-7">
            {{ $t('generic.color_accuracy_disclaimer') }}
          </p>
        </div>
        <div class="social-links flexed padding-medium-left flex-shrink" style="margin-top: 1.5em">
          <span>
            <a
              class="has-text-light social-links__link"
              :href="
                region == 'SAN'
                  ? 'https://www.instagram.com/bendersnorge/'
                  : 'https://www.instagram.com/benderssverige/'
              "
              target="_blank"
              title="Instagram"
            >
              <span class="icon"><font-awesome-icon :icon="['fab', 'instagram']" /></span>
            </a>
            <a
              class="has-text-light social-links__link"
              href="https://www.facebook.com/benderssverige/"
              target="_blank"
              title="Facebook"
            >
              <span class="icon"><font-awesome-icon :icon="['fab', 'facebook']" /></span>
            </a>
            <a
              class="has-text-light social-links__link"
              href="https://www.youtube.com/user/benderstv"
              target="_blank"
              title="Youtube"
            >
              <span class="icon"><font-awesome-icon :icon="['fab', 'youtube']" /></span>
            </a>
          </span>
        </div>
      </div>
    </section>
  </footer>
</template>

<script lang="ts">
  import { useAccountStore } from '@/stores/account';
  import { useTranslationStore } from '@/stores/translation';
  import { mapState, mapActions } from 'pinia';

  export default {
    name: 'FooterPartial',
    data() {
      return {};
    },
    computed: {
      ...mapState(useAccountStore, ['status', 'user', 'region']),
      ...mapState(useTranslationStore, ['divisionLinks']),
    },
    methods: {
      ...mapActions(useAccountStore, ['login', 'logout']),
    },
  };
</script>

<style scoped>
  .social-links a {
    display: inline-flex;
  }
</style>
