<template>
  <div>
    <!-- TODO: Check that this works -->
    <Teleport v-if="portal" :to="'#' + portal" :name="source">
      <portal-target :name="target"></portal-target>
    </Teleport>
    <portal-target v-else :name="target"></portal-target>

    <portal :to="target">
      <div v-if="detach" v-observe-visibility="visibilityChanged"></div>
      <transition name="notification">
        <div
          v-if="notificationExists"
          :class="['portal-notification', 'woocommerce-notices-wrapper', isDetached ? 'detached' : null]"
        >
          <!-- Persistent notifications -->
          <ul
            v-for="(notification, i) in persistent_notifications"
            :key="i"
            :class="'woocommerce-' + getBackground(notification.color)"
            role="alert"
          >
            <li>
              <span v-html="notification.message"></span>
              <span
                v-if="buttons.length > 0 || notification.buttons"
                class="notification-divider"
                style="margin-left: 0.5rem; margin-right: 0.5rem"
              >
                |
              </span>
              <a
                v-for="button in [...buttons, ...(notification.buttons || [])]"
                :key="button.value"
                class="has-text-weight-semibold"
                style="margin-right: 0.5rem"
                @click.stop.prevent="buttonEvent(button, notification)"
              >
                {{ button.text }}
              </a>
            </li>
          </ul>
          <!-- Regular notifications -->
          <ul
            v-for="(notification, i) in stored_notifications"
            :key="i"
            :class="'woocommerce-' + getBackground(notification.color)"
            role="alert"
            @mouseenter="preventClose = notification.message"
            @mouseleave="preventClose = null"
          >
            <li>
              <span v-html="notification.message"></span>
              <span
                v-if="buttons.length > 0 || notification.buttons"
                class="notification-divider"
                style="margin-left: 0.5rem; margin-right: 0.5rem"
              >
                |
              </span>
              <a
                v-for="button in [...buttons, ...(notification.buttons || [])]"
                :key="button.value"
                class="has-text-weight-semibold"
                style="margin-right: 0.5rem"
                @click.stop.prevent="buttonEvent(button, notification)"
              >
                {{ button.text }}
              </a>
            </li>
          </ul>
        </div>
      </transition>
    </portal>
  </div>
</template>

<script lang="ts">
  // import { PortalTarget, Portal } from 'portal-vue';
  import { NotificationButton, Notification } from '@/stores/notification';
  import { PropType } from 'vue';
  import { ObserveVisibility } from 'vue-observe-visibility';

  export default {
    name: 'NotificationComponent',
    // components: {
    //   PortalTarget,
    //   Portal,
    // },
    directives: {
      'observe-visibility': ObserveVisibility as any,
    },
    props: {
      notifications: Array as PropType<Notification[]>,
      buttons: {
        type: Array as PropType<NotificationButton[]>,
        default: () => {
          return [];
        },
      },
      portal: {
        default: '',
        type: String,
      },
      source: {
        default: '',
        type: String,
      },
      detach: {
        default: true,
        type: Boolean,
      },
    },
    data() {
      return {
        preventClose: null as string | null,
        stored_notifications: this.notifications as Notification[],
        isDetached: false as boolean,
        timeoutRef: {} as Record<string, NodeJS.Timeout>,
        wpadminbar: 0 as number,
        persistent_notifications: [] as Notification[],
      };
    },
    mounted() {
      // Add persistent message
      // this.persistent_notifications = [
      //     {
      //         message: 'Underhåll pågår. Beräknas färdigställas idag kl 17.',
      //         timeout: 0,
      //     }
      // ]
    },
    methods: {
      visibilityChanged(val) {
        this.isDetached = !val;
      },
      // onChange() {
      //   var e = new CustomEvent('bendersNotificationUpdate');
      //   window.dispatchEvent(e);
      // },
      getBackground(color: string = 'red') {
        let colors = {
          red: 'error',
          blue: 'message',
        };

        return colors[color];
      },
      buttonEvent(button, notification) {
        switch (button.value) {
          case 'to-checkout':
            this.$router.push('/checkout');
            clearTimeout(this.timeoutRef[notification.message]);
            this.stored_notifications = [];
            this.$emit('onTransitionEnd');
            break;
          case 'to-cquote':
            clearTimeout(this.timeoutRef[notification.message]);
            this.$router.push('/mina-sidor/quotes/' + button.data);
            this.stored_notifications = [];
            this.$emit('onTransitionEnd');
            break;
        }
        this.$emit('button-clicked', button.value);
      },
    },
    computed: {
      target() {
        return 'notification-portal-self' + this.source;
      },
      notificationExists() {
        return this.stored_notifications?.length > 0 || this.persistent_notifications?.length > 0;
      },
    },
    watch: {
      notifications: {
        immediate: true,
        deep: true,
        handler(notifications) {
          if (!notifications) return;
          if (notifications.some((n) => n.clear)) {
            this.stored_notifications = [];
            return;
          }
          if (notifications && notifications.length === 0) return;
          this.stored_notifications = [];

          // To trigger transition effects again
          this.$nextTick(() => {
            this.stored_notifications = [...notifications]; // Copy the notifications
          });
        },
      },
      stored_notifications(notifications) {
        for (let i = 0; i < notifications.length; i++) {
          const notification = notifications[i];
          const timeout = notification.timeout >= 0 ? notification.timeout : 4;

          clearTimeout(this.timeoutRef[notification.message]);
          if (timeout > 0) {
            this.$nextTick(() => {
              this.$emit('onTransitionStart'); // Removes the notification from the store
              // this.onChange();
            });
            const onTimeout = () => {
              if (this.preventClose == notification.message) {
                this.timeoutRef[notification.message] = setTimeout(onTimeout, (timeout / 2) * 1000);
                return;
              }

              // Remove the notification from the component store
              this.stored_notifications.splice(
                this.stored_notifications.findIndex((n) => n.message == notification.message),
                1
              );

              this.$emit('onTransitionEnd');
            };
            this.timeoutRef[notification.message] = setTimeout(onTimeout, timeout * 1000);
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'bulma/sass/utilities/mixins.sass';

  .portal-notification {
    position: relative;
    display: block;
    opacity: 1;
    width: 100%;
    z-index: 101;
    background-color: #a6192e;
    color: #fff;

    & > ul {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
    }
  }

  .mt-0 .portal-notification {
    margin-top: 0;
  }

  .portal-notification ul > li {
    display: flex;
    justify-content: center;
    @include mobile {
      flex-direction: column;
      .notification-divider {
        display: none;
      }
    }
  }

  .routeLogin .portal-notification {
    height: 0;
  }

  .portal-notification.detached {
    position: fixed;
    top: 0;
    margin-top: 0;
    left: 0;
    right: 0;
  }

  .notification-enter-from {
    display: block;
    opacity: 0;
    max-height: 0px;
  }
  .notification-leave-from {
    max-height: 500px;
  }
  .notification-enter-active {
    transition: all 0.25s ease-in;
  }
  .notification-leave-active {
    transition: all 0.25s ease-out;
  }
  .notification-leave-to {
    opacity: 0;
    max-height: 0px;
  }
  .notification-enter-to {
    opacity: 1;
    max-height: 500px;
  }
</style>
