/* eslint-disable no-unused-vars */
import { Emittable } from '@/data/productlist/generic.ts';

import _ from 'lodash';

/* Article Merging module
  @Requires
    - Articles
*/
export class ArticleMerging extends Emittable {
  cache: any[];
  disabled: boolean;
  constructor() {
    super();
    const self = this;
    this.cache = [];
    this.disabled = false;
    this.mixin = {
      data() {
        return {
          c_undo_notifications: [{ clear: true }],
        };
      },
      methods: {
        p_c_notification_onClick(event) {
          switch (event) {
            case 'undo':
              self.undo.call(self, this);
              break;
            default:
              this.p_c_setUndoNotifications(0);
              self.clear.call(self);
          }
        },
        p_c_setUndoNotifications(value) {
          this.c_undo_notifications =
            value > 0
              ? [
                  {
                    message: this.$t('productlist.rows_merged', value, { n: value }),
                    timeout: 0,
                  },
                ]
              : [{ clear: true }];
        },
      },
    };
  }

  init() {
    this.cache = [];
    this.on('merge:undo', this.undo.bind(this));
    this.on('merge:clear', this.clear.bind(this));
    this.on('merge:disable', () => {
      this.disabled = true;
    });
    this.on('merge:enable', () => {
      this.disabled = false;
    });
  }

  modify(vm, rows) {
    if (this.disabled) return rows;

    // Merge articles with the same unit selected
    let showNotification = false;
    let mergedRows = [] as any[];
    let modifiedRows = rows.reduce((acc, row) => {
      if (mergedRows.includes(row.id)) return acc;

      for (let r of rows) {
        if (row.id === r.id) continue;
        if (row.article_id === r.article_id && row.unit === r.unit && row.batchnr === r.batchnr) {
          if (this.store('debug', 'debugMode')) console.debug('Merging articles', row.id, r.id);
          if (!row.batchnr) {
            row.amount = Number(row.amount) + Number(r.amount);
            row.was_merged = true;
            showNotification = true;
          }
          mergedRows.push(r.id);
        }
      }

      // Add the row to the new rows
      acc.push(row);

      return acc;
    }, []);

    // If changes were made, commit them
    if (rows.length > modifiedRows.length) {
      if (showNotification) this.cacheMarker();
      // Emit on parent
      this.emit(vm, {
        name: '_emit',
        event: {
          name: 'update',
          value: _.cloneDeep(modifiedRows),
        },
      });
      if (showNotification) {
        this.emit(vm, {
          name: 'merge:undo-length',
          value: this.getMarkerCount(),
        });
        vm.p_c_setUndoNotifications(this.getMarkerCount());
      }
    }

    this.cacheRows(vm, modifiedRows);
    return modifiedRows;
  }

  cacheRows(_vm, rows) {
    // Remove first value if it is not a marker
    if (this.cache.length > 0 && this.cache[0] !== null) {
      this.cache.shift();
    }
    this.cache.unshift(_.cloneDeep(rows));
  }

  cacheMarker() {
    this.cache.unshift(null);
  }

  getMarkerCount() {
    return this.cache.reduce((acc, marker) => acc + (marker === null ? 1 : 0), 0);
  }

  undo(vm) {
    if (this.cache.length === 0) return;
    // Remove everything from cache up until and including the marker
    this.cache.splice(0, this.cache.indexOf(null) + 1);

    // Emit on parent
    this.emit(vm, {
      name: '_emit',
      event: {
        name: 'update',
        value: this.cache[0],
      },
    });

    this.emit(vm, {
      name: 'merge:undo-length',
      value: this.getMarkerCount(),
    });
    vm.p_c_setUndoNotifications(this.getMarkerCount());
  }

  // Clear cache
  clear() {
    this.cache = [];
  }
}
