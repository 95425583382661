<template>
  <svg
    id="box-loader"
    :class="[size, hidden || delayed ? 'is-hidden' : null]"
    width="341px"
    height="152px"
    viewBox="0 0 341 152"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="boxes" transform="translate(2.848000, 3.169000)" fill="#9F2A3B" fill-rule="nonzero">
      <polygon
        class="box"
        id="box-1"
        points="-5.32907052e-15 145.985 21.26 145.985 21.26 119.055 -5.32907052e-15 119.055"
      ></polygon>
      <polygon class="box" id="box-2" points="24.094 145.985 45.354 145.985 45.354 119.055 24.094 119.055"></polygon>
      <polygon class="box" id="box-3" points="48.189 145.985 69.449 145.985 69.449 119.055 48.189 119.055"></polygon>
      <polygon class="box" id="box-4" points="72.283 145.985 93.543 145.985 93.543 119.055 72.283 119.055"></polygon>
      <polygon class="box" id="box-5" points="96.378 145.985 117.637 145.985 117.637 119.055 96.378 119.055"></polygon>
      <polygon
        class="box"
        id="box-6"
        points="120.472 145.985 141.732 145.985 141.732 119.055 120.472 119.055"
      ></polygon>
      <polygon
        class="box"
        id="box-7"
        points="144.566 145.985 165.826 145.985 165.826 119.055 144.566 119.055"
      ></polygon>
      <polygon
        class="box"
        id="box-8"
        points="168.661 145.985 189.921 145.985 189.921 119.055 168.661 119.055"
      ></polygon>
      <polygon
        class="box"
        id="box-9"
        points="192.756 145.985 214.015 145.985 214.015 119.055 192.756 119.055"
      ></polygon>
      <polygon class="box" id="box-10" points="216.85 145.985 238.11 145.985 238.11 119.055 216.85 119.055"></polygon>
      <polygon
        class="box"
        id="box-11"
        points="240.944 145.985 262.204 145.985 262.204 119.055 240.944 119.055"
      ></polygon>
      <polygon
        class="box"
        id="box-12"
        points="265.039 145.985 286.298 145.985 286.298 119.055 265.039 119.055"
      ></polygon>
      <polygon
        class="box"
        id="box-13"
        points="289.132 145.985 310.392 145.985 310.392 119.055 289.132 119.055"
      ></polygon>
      <polygon
        class="box"
        id="box-14"
        points="313.226 145.985 334.486 145.985 334.486 119.055 313.226 119.055"
      ></polygon>
      <polygon
        class="box"
        id="box-15"
        points="-5.32907052e-15 116.221 21.26 116.221 21.26 89.292 -5.32907052e-15 89.292"
      ></polygon>
      <polygon class="box" id="box-16" points="24.094 116.221 45.354 116.221 45.354 89.292 24.094 89.292"></polygon>
      <polygon class="box" id="box-17" points="289.132 116.221 310.392 116.221 310.392 89.292 289.132 89.292"></polygon>
      <polygon class="box" id="box-18" points="313.226 116.221 334.486 116.221 334.486 89.292 313.226 89.292"></polygon>
      <polygon
        class="box"
        id="box-19"
        points="-5.32907052e-15 86.457 21.26 86.457 21.26 59.528 -5.32907052e-15 59.528"
      ></polygon>
      <polygon class="box" id="box-20" points="24.094 86.457 45.354 86.457 45.354 59.528 24.094 59.528"></polygon>
      <polygon class="box" id="box-21" points="289.132 86.457 310.392 86.457 310.392 59.528 289.132 59.528"></polygon>
      <polygon class="box" id="box-22" points="313.226 86.457 334.486 86.457 334.486 59.528 313.226 59.528"></polygon>
      <polygon
        class="box"
        id="box-23"
        points="-5.32907052e-15 56.693 21.26 56.693 21.26 29.764 -5.32907052e-15 29.764"
      ></polygon>
      <polygon class="box" id="box-24" points="24.094 56.693 45.354 56.693 45.354 29.764 24.094 29.764"></polygon>
      <polygon class="box" id="box-25" points="289.132 56.693 310.392 56.693 310.392 29.764 289.132 29.764"></polygon>
      <polygon class="box" id="box-26" points="313.226 56.693 334.486 56.693 334.486 29.764 313.226 29.764"></polygon>
      <polygon
        class="box"
        id="box-27"
        points="-5.32907052e-15 26.93 21.26 26.93 21.26 -2.48689958e-14 -5.32907052e-15 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-28"
        points="24.094 26.93 45.354 26.93 45.354 -2.48689958e-14 24.094 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-29"
        points="48.189 26.93 69.449 26.93 69.449 -2.48689958e-14 48.189 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-30"
        points="72.283 26.93 93.543 26.93 93.543 -2.48689958e-14 72.283 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-31"
        points="96.378 26.93 117.637 26.93 117.637 -2.48689958e-14 96.378 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-32"
        points="120.472 26.93 141.732 26.93 141.732 -2.48689958e-14 120.472 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-33"
        points="144.566 26.93 165.826 26.93 165.826 -2.48689958e-14 144.566 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-34"
        points="168.661 26.93 189.921 26.93 189.921 -2.48689958e-14 168.661 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-35"
        points="192.756 26.93 214.015 26.93 214.015 -2.48689958e-14 192.756 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-36"
        points="216.85 26.93 238.11 26.93 238.11 -2.48689958e-14 216.85 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-37"
        points="240.944 26.93 262.204 26.93 262.204 -2.48689958e-14 240.944 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-38"
        points="265.039 26.93 286.298 26.93 286.298 -2.48689958e-14 265.039 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-39"
        points="289.132 26.93 310.392 26.93 310.392 -2.48689958e-14 289.132 -2.48689958e-14"
      ></polygon>
      <polygon
        class="box"
        id="box-40"
        points="313.226 26.93 334.486 26.93 334.486 -2.48689958e-14 313.226 -2.48689958e-14"
      ></polygon>
    </g>
    <g id="text" transform="translate(56.979000, 48.206000)" fill="#005093" fill-rule="nonzero">
      <path
        d="M13.13,20.449 C17.912,20.449 20.569,18.171 20.569,14.225 C20.569,10.354 18.064,8.153 13.662,8.153 L10.323,8.153 L10.323,20.449 L13.13,20.449 Z M15.94,42.612 C21.251,42.612 23.985,40.259 23.985,35.781 C23.985,31.075 21.024,28.722 15.028,28.722 L10.323,28.722 L10.323,42.612 L15.94,42.612 Z M-7.10542736e-15,0.107 L16.622,0.107 C25.123,0.107 30.74,5.192 30.74,12.783 C30.74,18.324 28.159,21.815 22.77,23.637 C30.816,25.686 34.611,29.709 34.611,36.387 C34.611,44.964 28.235,50.657 18.596,50.657 L-7.10542736e-15,50.657 L-7.10542736e-15,0.107 L-7.10542736e-15,0.107 Z"
      ></path>
      <path
        d="M60.133,31.15 C59.678,26.9 57.098,24.471 53.226,24.471 C49.507,24.471 46.851,26.9 46.092,31.15 L60.133,31.15 Z M68.71,43.37 C65.902,48.76 60.285,51.871 53.226,51.871 C43.131,51.871 36.376,45.117 36.376,34.946 C36.376,24.471 43.208,17.489 53.453,17.489 C63.168,17.489 69.469,24.016 69.469,34.111 L69.469,37.299 L46.167,37.299 C46.624,41.777 49.204,44.205 53.453,44.205 C57.021,44.205 59.146,42.839 60.892,39.499 L68.71,43.37 L68.71,43.37 Z"
      ></path>
      <path
        d="M72.162,18.703 L81.27,18.703 L81.27,22.802 C84.23,19.006 87.266,17.489 91.971,17.489 C99.334,17.489 102.901,21.435 102.901,29.405 L102.901,50.657 L93.414,50.657 L93.414,31.909 C93.414,27.431 91.82,25.458 88.177,25.458 C83.774,25.458 81.649,28.418 81.649,34.49 L81.649,50.657 L72.162,50.657 L72.162,18.703"
      ></path>
      <path
        d="M115.236,34.869 C115.236,40.259 118.576,43.902 123.509,43.902 C128.747,43.902 132.086,40.334 132.086,34.718 C132.086,29.253 128.594,25.458 123.584,25.458 C118.728,25.458 115.236,29.405 115.236,34.869 Z M131.631,47.013 C128.747,50.353 125.711,51.719 120.928,51.719 C111.668,51.719 105.369,44.888 105.369,34.718 C105.369,24.623 111.668,17.716 120.928,17.716 C125.027,17.716 127.835,18.779 131.099,21.663 L131.095,-2.84217094e-14 L140.477,-2.84217094e-14 L140.586,50.657 L131.631,50.657 L131.631,47.013 L131.631,47.013 Z"
      ></path>
      <path
        d="M167.06,31.15 C166.604,26.9 164.024,24.471 160.153,24.471 C156.434,24.471 153.777,26.9 153.018,31.15 L167.06,31.15 Z M175.636,43.37 C172.829,48.76 167.212,51.871 160.153,51.871 C150.058,51.871 143.303,45.117 143.303,34.946 C143.303,24.471 150.134,17.489 160.38,17.489 C170.096,17.489 176.396,24.016 176.396,34.111 L176.396,37.299 L153.094,37.299 C153.55,41.777 156.129,44.205 160.38,44.205 C163.948,44.205 166.073,42.839 167.819,39.499 L175.636,43.37 L175.636,43.37 Z"
      ></path>
      <path
        d="M179.088,18.703 L188.197,18.703 L188.197,24.471 C191.004,19.614 193.357,17.792 196.925,17.792 C197.607,17.792 198.291,17.868 199.96,18.096 L199.96,28.342 C197.684,27.583 196.318,27.28 194.724,27.28 C190.854,27.28 188.576,30.088 188.576,34.869 L188.576,50.657 L179.088,50.657 L179.088,18.703"
      ></path>
      <path
        d="M207.91,40.486 C209.43,43.598 210.871,44.813 213.148,44.813 C215.122,44.813 216.564,43.674 216.564,42.004 C216.564,40.183 215.805,39.651 210.188,37.526 C203.586,34.946 201.536,32.593 201.536,27.735 C201.536,21.435 206.696,17.033 214.059,17.033 C220.13,17.033 223.926,19.386 226.203,24.471 L218.84,28.19 C217.323,25.23 216.107,24.243 213.983,24.243 C212.161,24.243 210.947,25.306 210.947,26.9 C210.947,28.722 212.009,29.48 216.412,30.923 C223.394,33.2 226.127,36.16 226.127,41.397 C226.127,47.925 221.193,52.175 213.452,52.175 C207.227,52.175 203.129,49.746 200.474,44.509 L207.91,40.486"
      ></path>
    </g>
  </svg>
</template>

<script lang="ts">
  export default {
    name: 'BendersLoader',
    props: {
      size: String,
      hidden: {
        default: true,
        type: Boolean,
      },
      delay: {
        default: 0,
        type: Number,
      },
    },
    data() {
      return {
        delayed: false,
      };
    },
    computed: {},
    methods: {},
    watch: {
      hidden: {
        handler(val) {
          if (val === false) {
            this.delayed = true;
            setTimeout(() => {
              this.delayed = false;
            }, this.delay);
          }
        },
        immediate: true,
      },
    },
  };
</script>
