import { type ComponentOptionsMixin } from 'vue';

import _ from 'lodash';

import { useCartStore } from '@/stores/cart';
import { useDebugStore } from '@/stores/debug';
import { DataStore } from './datastore';
import { useAccountStore } from '@/stores/account';
import { useHistoryStore } from '@/stores/history';
import { useNotificationStore } from '@/stores/notification';
import { usePostalcodeStore } from '@/stores/postalcode';
import { useTranslationStore } from '@/stores/translation';

const storeMap = {
  account: useAccountStore,
  cart: useCartStore,
  debug: useDebugStore,
  history: useHistoryStore,
  notification: useNotificationStore,
  postalcode: usePostalcodeStore,
  translation: useTranslationStore,
};

export class Emittable {
  _store: Record<string, any> = {};
  mixin: ComponentOptionsMixin = {};
  composable: (...props: any) => any = () => {}; // Fake 'vm' to replace mixin in composition API
  handlers: Record<string, ((vm: any, event: any) => void)[]> = {};
  constructor() {
    this.handlers = {};
  }

  store(store: string, key: string): any | null {
    if (!(store in this._store)) {
      if (!(store in storeMap)) return null;
      this._store[store] = storeMap[store]();
    }
    if (!(key in this._store[store])) {
      if (!(key in this._store[store].store)) return null;
      this._store[store][key] = this._store[store];
    }
    return this._store[store][key];
  }

  on(eventName, callback) {
    if (!this.handlers[eventName]) {
      this.handlers[eventName] = [];
    }
    this.handlers[eventName].push(callback);
  }

  // eslint-disable-next-line no-unused-vars
  emit(vm, event) {
    if (this.handlers[event.name]) {
      this.handlers[event.name].forEach((callback) => callback(vm, event));
    }
  }

  modify(_vm, rows) {
    return rows;
  }

  init() {}
  _init() {
    this.handlers = {};
    this.init();
  }
}

export class Controller {
  _store: Record<string, any> = {};
  dataStore?: DataStore;
  mixin: ComponentOptionsMixin;
  mixins: ComponentOptionsMixin[] = [];
  modifiers: Emittable[] = [];
  constructor() {
    const self = this;
    this.mixin = {
      data() {
        return {
          c_list_loaded: false,
          pl_modifying: false,
        };
      },
      computed: {
        p_c_customer_number() {
          return this.customer_number || this.customerNumber || self.store('cart', 'customerNumber');
        },
        p_c_address_id() {
          return this.address_id ?? self.store('cart', 'addressId');
        },
        p_c_rows() {
          if (self.store('debug', 'debugMode')) console.debug('Persistent updated', _.cloneDeep(this.persistent));
          let list = _.cloneDeep(this.persistent);
          if (_.isEmpty(list)) return null;

          // Note about computed value in the modifiers.
          // These can cause infinite loop updates, so we need to be careful
          // Modify list data
          return self.modify(this, list.rows);
        },
      },
      methods: {
        emit(event) {
          self.emit(this, event);
        },
        on(eventName, callback) {
          self.on(eventName, callback);
        },
      },
    };
  }

  store(store: string, key: string): any | null {
    if (!(store in this._store)) {
      if (!(store in storeMap)) return null;
      this._store[store] = storeMap[store]();
    }
    if (!(key in this._store[store])) {
      if (!(key in this._store[store].store)) return null;
      this._store[store][key] = this._store[store];
    }
    return this._store[store][key];
  }

  modify(vm, rows) {
    vm.pl_modifying = true;
    for (const m of this.modifiers) {
      // This a bit weird, but the child modifiers need a clone of the rows
      // But this modifier needs the original rows to prevent infinite loop
      rows = m.modify(vm, _.cloneDeep(rows));
      // debug empty rows, as they will cause issues
      if (this.store('debug', 'debugMode')) {
        if (rows.find((r) => _.isEmpty(r))) {
          console.error('Empty row found, fix this as this will cause issues', rows, m);
          // eslint-disable-next-line no-debugger
          debugger;
        }
      }
    }
    vm.$nextTick(() => {
      vm.pl_modifying = false;
    });
    return rows;
  }

  on(eventName, callback) {
    this.modifiers.forEach((m) => {
      m.on(eventName, callback);
    });
  }

  emit(vm, event) {
    this.modifiers.forEach((m) => {
      m.emit(vm, event);
    });
  }

  init() {
    this.modifiers.forEach((m) => {
      // Initialize modifier
      m._init(); // This clears the handlers

      // Nested emit
      m.on('_emit', (vm, event) => {
        if (this.store('debug', 'debugMode')) console.debug('_emit', event);
        if ('event' in event) this.emit(vm, event.event);
      });
    });
  }
}
