/* eslint-disable no-unused-vars */
import _ from 'lodash';
import i18n from '@/languages/i18n';

import { Emittable } from '@/data/productlist/generic.ts';
import {
  getCheckout,
  getLists,
  updateList,
  removeList,
  addToList,
  setListWarehouse,
  setListName,
} from '@/data/api/kundportal/requests';

export class DataStore extends Emittable {
  confirm_delete: boolean;
  auto_save: boolean;
  allow_save: boolean;
  excluded_events: string[];
  old_list: any;
  setListWarehouse: any;
  saveListDebounced: any;
  sortingFunc: any;
  constructor({
    confirm_delete = false,
    auto_save = false,
    excluded_events = [],
    saveListDebounce = 1000,
    sortingFunc = null,
    allow_save = true,
  }: {
    confirm_delete?: boolean;
    auto_save?: boolean;
    allow_save?: boolean;
    excluded_events?: string[];
    saveListDebounce?: number;
    sortingFunc?: any;
  } = {}) {
    super();
    const self = this;
    this.confirm_delete = confirm_delete;
    this.auto_save = auto_save;
    this.allow_save = allow_save;
    this.excluded_events = excluded_events;
    this.setListWarehouse = _.debounce((listId, warehouseId) => {
      setListWarehouse(listId, warehouseId);
    }, 500);
    this.saveListDebounced = _.debounce(this.saveList.bind(this), saveListDebounce);
    this.sortingFunc = typeof sortingFunc === 'function' ? sortingFunc : null;
    this.mixin = {
      data() {
        return {
          persistent: {},
          c_has_changes: false,
        };
      },
      watch: {
        p_c_rows: {
          deep: true,
          handler() {
            if (self.auto_save) self.saveListDebounced(this);
          },
        },
      },
    };
  }

  init() {
    this.old_list = null;

    // General/Multi class handlers
    this.on('update', this.update.bind(this));
    this.on('warehouse-changed', this.handleEvent.bind(this));

    // Class specific handlers
    const events = [
      'store:set-rows',
      'store:add-to-cart',
      'store:remove-from-list',
      'store:remove-list',
      // 'store:attempt-save-list',
      'store:save-list',
      'store:tombstoned',
      'store:empty-list',
      'store:rename-list',
    ];

    for (let event of events) {
      if (!this.excluded_events.includes(event)) this.on(event, this.handleEvent.bind(this));
    }
  }

  checkForChanges(vm) {
    vm.c_has_changes = !_.isEqual(this.old_list, vm.persistent);
  }

  update(vm, event) {
    if (this.store('debug', 'debugMode')) console.debug('persistent:update', event);
    if (event.key) {
      let index = vm.persistent.rows.findIndex((row) => row.id === event.row);
      if (index === -1) return;
      vm.persistent.rows[index][event.key] = event.value;
    } else {
      vm.persistent.rows = event.value; // Replaces all rows with new rows
    }
    this.checkForChanges(vm);
  }

  async handleEvent(vm, event) {
    switch (event.name) {
      case 'store:set-rows':
        // Note this used to be 'this' instead of 'vm', but 'this' shouldn't have worked?
        console.debug('persistent:set-rows', event.value);
        vm.persistent = {
          list: {},
          rows: event.value,
        };
        break;
      case 'store:add-to-cart':
        this.addToCart(vm, event.value);
        break;
      case 'store:attempt-save-list':
      case 'store:save-list':
        // console.debug("Saving list")
        await this.saveList(vm);
        this.checkForChanges(vm);
        break;
      case 'store:remove-from-list':
        if (!event.value && event.row) event.value = [event.row];
        this.removeFromList(vm, event.value);
        this.checkForChanges(vm);
        break;
      case 'store:remove-list':
        this.removeList(vm, event.value);
        break;
      case 'store:tombstoned':
        this.tombstone(vm, event.value);
        break;
      case 'warehouse-changed':
        this.checkForChanges(vm);
        break;
      case 'store:empty-list':
        this.emptyList(vm, event.value);
        break;
      case 'store:rename-list':
        this.renameList(vm, event.value);
        break;
    }
  }

  modify(_vm, rows) {
    if (this.sortingFunc) {
      rows = this.sortingFunc(rows);
    }
    return rows;
  }

  set(vm, list) {
    if (this.store('debug', 'debugMode')) console.debug('persistent:set', list);
    vm.persistent = list;
    this.old_list = _.cloneDeep(list);
  }

  async get(vm, id, query = {}) {
    const isCart = id == this.store('cart', 'id');

    let list;
    if (isCart) {
      list = await getCheckout().then((res: any) => res.list);
    } else {
      list = await getLists(id, {
        ...query,
      }).then((res: any) => res.data);
    }

    if (_.isEmpty(list)) return;

    this.old_list = _.cloneDeep(list);
    vm.persistent = list;

    if (isCart) this.store('cart', 'setCart')?.(list);

    return list;
  }

  async saveList(vm) {
    if (!this.allow_save) return;

    if (!vm.persistent?.list) {
      if (this.store('debug', 'debugMode')) console.debug('persistent:saveList, no persistent found?', vm.persistent);
      return;
    }
    const list = vm.persistent.list;
    const rows = vm.persistent.rows;
    const id = list.list_id || list.id;

    if (!id) {
      if (this.store('debug', 'debugMode')) console.debug('persistent:saveList, no id found?', vm.persistent);
      return;
    }

    const isCart = id == this.store('cart', 'id');

    const resp: any = await updateList(id, rows, isCart);
    if (resp.success && resp.data) {
      for (let i = 0; i < resp.data.length; i++) rows.splice(resp.data[i], 1);
    }

    if (list.list_id && list.warehouse) this.setListWarehouse(list.list_id, list.warehouse);

    this.old_list = _.cloneDeep(vm.persistent);
    if (isCart) this.store('cart', 'setCart')?.(vm.persistent);
    this.emit(vm, {
      name: 'store:saved-list',
      success: true,
    });
  }

  addToCart(vm, rows) {
    let articleNames = rows.reduce((arr, row) => {
      arr.push(row.name);
      return arr;
    }, []);
    let message = i18n.global.t('cart.success.adding_article_to_cart', { name: articleNames[0] });
    if (articleNames.length >= 4) {
      let names = articleNames.splice(0, 2);
      message = i18n.global.t('cart.success.adding_articles_to_cart_alot', {
        names: names.join(', '),
        amount: articleNames.length,
      });
    } else if (articleNames.length >= 2) {
      let lastName = articleNames.splice(-1);
      message = i18n.global.t('cart.success.adding_articles_to_cart_multiple', {
        names: articleNames.join(', '),
        lastName: lastName,
      });
    }

    this.store('notification', 'clearNotification')?.();
    this.store(
      'notification',
      'addNotification'
    )?.({
      message,
      buttons: [{ text: i18n.global.t('checkout.to_checkout'), value: 'to-checkout' }],
    });

    addToList(this.store('cart', 'id'), rows, true).then(() => {
      this.emit(vm, {
        name: 'store:added-to-cart',
      });
    });
  }

  removeFromList(vm, ids) {
    const rows = vm.persistent.rows;

    if (!this.confirm_delete || confirm(i18n.global.t('list.shopping_list.confirm_remove_from_list', ids.length))) {
      ids.forEach((id) => {
        let index = rows.findIndex((row) => row.id === id);
        if (index > -1) rows.splice(index, 1);
      });
    }

    this.emit(vm, {
      name: 'store:removed-from-list',
    });
  }

  removeList(vm, id) {
    if (confirm(i18n.global.t('list.shopping_list.confirm_remove_list'))) {
      removeList(id || vm.persistent.list.list_id || vm.persistent.list.id)
        .then(() => {
          this.emit(vm, {
            name: 'store:removed-list',
          });
        })
        .catch((e) => {
          console.error(e);
          this.store('notification', 'clearNotification')?.();
          this.store(
            'notification',
            'addNotification'
          )?.({ message: i18n.global.t('list.shopping_list.failed_removing_list') + '...' });
        })
        .finally(() => {
          this.emit(vm, {
            name: 'store:removed-from-list',
          });
        });
    } else {
      this.emit(vm, {
        name: 'store:removed-from-list',
      });
    }
  }

  emptyList(vm, message) {
    if (!message || confirm(message)) {
      if (this.store('debug', 'debugMode')) console.debug('persistent:emptyList', vm.persistent);
      vm.persistent.rows = [];
      this.emit(vm, {
        name: 'store:removed-from-list',
      });
    }
  }
  renameList(vm, value) {
    setListName(value.id || vm.persistent.list.list_id || vm.persistent.list.id, value.name)
      .then((response: any) => {
        if (this.store('debug', 'debugMode')) console.debug('persistent:renameList', vm.persistent);
        vm.persistent.list.list_name = value.name;
        this.emit(vm, {
          name: 'store:renamed-list',
          newName: value.name,
          ...response,
        });
      })
      .catch(() => {
        this.emit(vm, {
          name: 'store:renamed-list',
          newName: value.name,
          success: false,
          data: i18n.global.t('errors.generic_error'),
        });
      });
  }

  tombstone(vm, tombstoned) {
    const rows = vm.persistent.rows;

    tombstoned.forEach((r) => {
      let index = rows.findIndex((row) => row.id === r.id);
      if (this.store('debug', 'debugMode')) console.debug('persistent:tombstone', r, index);
      if (index > -1) vm.persistent.rows[index]['tombstoned'] = '1';
    });

    if (tombstoned.length) {
      this.store('notification', 'clearNotification')?.();
      this.store(
        'notification',
        'addNotification'
      )?.({
        message: i18n.global.t('list.tombstone_notification'),
      });
    }
  }
}
