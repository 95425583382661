/* eslint-disable no-unused-vars */
import { Controller } from '@/data/productlist/generic.ts';

import { DataStore } from '@/data/productlist/datastore.ts';
import { Articles } from '@/data/productlist/articles.ts';
import { ArticleMerging } from '@/data/productlist/article_merging.ts';
import { LineIndexing } from '@/data/productlist/line_indexing.ts';
import { Pallets } from '@/data/productlist/pallets.ts';
import { Warehouse } from '@/data/productlist/warehouse.ts';
import { Price } from '@/data/productlist/price.ts';
import { Checkboxes } from '@/data/productlist/checkboxes.ts';
import { Totals } from '@/data/productlist/totals.ts';
import { Address } from '@/data/productlist/address.ts';
import { Delivery } from '@/data/productlist/delivery.ts';

import { getOrderM3 } from '@/data/api/freight/requests';

import _ from 'lodash';

/*
    Isssues
    - Auto saving the cart after an article merge sometimes doesn't work, even with a debounce
*/

const cartSortingFunc = (rows) => {
  const sorted = [] as any[];
  while (rows.length > 0) {
    const r = rows.shift();
    if (r.batchnr) {
      const batchRows = [r, ...rows].filter((r2) => r2.batchnr === r.batchnr);
      const sortedBatchRows = _.sortBy(batchRows, (r2) => {
        return [r2._meta?.treatment];
      });
      sorted.push(...sortedBatchRows);
      rows = rows.filter((r2) => !batchRows.includes(r2));
    } else {
      sorted.push(r);
    }
  }

  return sorted;
};

// Controllers
export class ShoppingListController extends Controller {
  constructor() {
    super();
    this.dataStore = new DataStore({
      confirm_delete: true,
    });
    this.modifiers = [
      new Articles({
        fetchSlaves: true,
      }),
      new ArticleMerging(),
      new Pallets(),
      new Price({
        price: true,
        linePrice: true,
        priceVisibility: 'netPrice',
        automaticCurrency: true,
      }),
      new Warehouse(),
      new Checkboxes(),
      new Totals(),
      this.dataStore,
    ];
    this.mixins = [this.mixin, ...this.modifiers.map((m) => m.mixin).filter(Boolean)];
  }

  async get(vm, id, params?: any) {
    const list = await this.dataStore?.get(vm, id, params);
    vm['c_list_loaded'] = true;
    return list;
  }
}

export class SearchController extends Controller {
  constructor() {
    super();
    this.dataStore = new DataStore({
      excluded_events: ['store:attempt-save-list', 'store:save-list'],
    });
    this.modifiers = [
      new Articles({
        fetchSlaves: true,
      }),
      new Price({
        price: true,
        linePrice: true,
        priceVisibility: null,
      }),
      // new Warehouse(),
      this.dataStore,
    ];
    this.mixins = [this.mixin, ...this.modifiers.map((m) => m.mixin).filter(Boolean)];
  }

  async get(vm, id, params) {
    const list = await this.dataStore?.get(vm, id, params);
    vm['c_list_loaded'] = true;
    return list;
  }
}

export class CartController extends Controller {
  constructor() {
    super();
    this.dataStore = new DataStore({
      auto_save: true,
      saveListDebounce: 300,
      sortingFunc: cartSortingFunc,
    });
    this.modifiers = [
      new Articles({
        fetchSlaves: true,
      }),
      new ArticleMerging(),
      new Pallets(),
      new Price({
        price: true,
        linePrice: true,
        priceVisibility: 'netPrice',
        automaticCurrency: true,
      }),
      new Warehouse(),
      new Totals(),
      this.dataStore,
    ];
    this.mixins = [this.mixin, ...this.modifiers.map((m) => m.mixin).filter(Boolean)];
  }

  async get(vm, id, params = {}) {
    const list = await this.dataStore?.get(vm, id, params);
    vm['c_list_loaded'] = true;
    return list;
  }

  set(vm, _id, data) {
    return this.dataStore?.set(vm, data);
  }
}

export class CheckoutController extends Controller {
  constructor() {
    super();
    this.dataStore = new DataStore({
      auto_save: true,
      sortingFunc: cartSortingFunc,
    });
    this.modifiers = [
      new Address(),
      new Articles({
        fetchSlaves: true,
      }),
      new ArticleMerging(),
      new Pallets(),
      new Delivery(),
      new Price({
        price: true,
        linePrice: true,
        priceVisibility: 'netPrice',
        fetchSalesAndNet: true,
        usePayerRegion: true,
      }),
      new Warehouse(),
      new Checkboxes(), // used to determine if a warehouse is valid
      new Totals(),
      this.dataStore,
    ];
    this.mixins = [this.mixin, ...this.modifiers.map((m) => m.mixin).filter(Boolean)];
  }

  async get(vm, id, params?: any) {
    const list = await this.dataStore?.get(vm, id, params);
    vm['c_list_loaded'] = true;
    return list;
  }

  set(vm, _id, data) {
    return this.dataStore?.set(vm, data);
  }
}

export class CheckoutControllerV2 extends Controller {
  constructor() {
    super();
    this.dataStore = new DataStore({
      auto_save: true,
      sortingFunc: cartSortingFunc,
    });
    this.modifiers = [
      new Address(),
      new Articles({
        fetchSlaves: true,
      }),
      new ArticleMerging(),
      new LineIndexing(),
      // new Pallets(),
      new Delivery({
        modifiable: false,
      }),
      new Price({
        price: true,
        linePrice: true,
        priceVisibility: 'netPrice',
        fetchSalesAndNet: true,
        usePayerRegion: true,
      }),
      new Warehouse(),
      new Checkboxes(), // used to determine if a warehouse is valid
      new Totals(),
      this.dataStore,
    ];
    this.mixins = [this.mixin, ...this.modifiers.map((m) => m.mixin).filter(Boolean)];
  }

  async get(vm, id, params?: { isOrdernumber?: boolean; guid?: string }) {
    if (!this.dataStore) {
      console.warn('No dataStore found');
      return;
    }

    let data;
    if (params?.isOrdernumber) {
      this.dataStore.allow_save = false;
      this.emit(vm, { name: 'merge:disable' });
      this.emit(vm, { name: 'article:disable-tombstone' });
      data = await getOrderM3(id, params?.guid);
    } else {
      this.dataStore.allow_save = true;
      this.emit(vm, { name: 'merge:enable' });
      this.emit(vm, { name: 'article:enable-tombstone' });
      data = await this.dataStore.get(vm, id, params);
    }

    vm['c_list_loaded'] = true;
    return data;
  }

  set(vm, _id, data) {
    return this.dataStore?.set(vm, data);
  }
}

export class WeberController extends Controller {
  constructor() {
    super();
    this.dataStore = new DataStore({
      excluded_events: ['store:attempt-save-list', 'store:save-list'],
    });
    this.modifiers = [
      new Articles({
        fetchSlaves: true,
      }),
      new Price({
        price: true,
        linePrice: true,
        priceVisibility: null,
      }),
      this.dataStore,
    ];
    this.mixins = [this.mixin, ...this.modifiers.map((m) => m.mixin).filter(Boolean)];
  }

  async get(vm, id, params) {
    const list = await this.dataStore?.get(vm, id, params);
    vm['c_list_loaded'] = true;
    return list;
  }
}

export class BatchController extends Controller {
  constructor() {
    super();
    this.dataStore = new DataStore({
      excluded_events: ['store:attempt-save-list', 'store:save-list'],
    });
    this.modifiers = [
      new Articles({
        fetchSlaves: true,
      }),
      new Warehouse(),
      new Price({
        price: true,
        linePrice: true,
        priceVisibility: null,
      }),
      this.dataStore,
    ];
    this.mixins = [this.mixin, ...this.modifiers.map((m) => m.mixin).filter(Boolean)];
  }

  async get(vm, id, params) {
    const list = await this.dataStore?.get(vm, id, params);
    vm['c_list_loaded'] = true;
    return list;
  }
}
