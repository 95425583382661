import { defineStore } from 'pinia';
import { getCartInfo, setListWarehouse } from '@/data/api/kundportal/requests';

import _ from 'lodash';

function validateCart(cart) {
  return _.isEmpty(cart) ? {} : cart;
}

enum WarehouseLogic {
  postalcode = 'postalcode',
  warehouse = 'warehouse',
}

interface List {
  list_id: string;
  type: string;
  list_name: string;
  author: string;
  customer_number: string;
  created: string;
  cart_hash: string;
  warehouse: string;
  addresses?: unknown[];
  defaultStockAddress: unknown;
}

interface Row {
  id: string;
  list_id: string;
  article_id: string;
  warehouse: string;
  unit: string;
  amount: number;
  artnr: string;
  tombstoned: string; // Technically a boolean of 0/1
  added: string;
  hash: string;
  comment: string;
  batchnr: string;
  _meta: Record<string, string>;
}

interface PickUpTime {
  date: string;
  slots: string[];
}

interface Warehouse {
  id: string;
  label: string;
  categories: string[];
  default: boolean;
  defaultFor: string[]; // Doublecheck
  zipCode: string;
  nextOpenDay: string;
  earliestPickup: string;
  itemAssortment: string;
  pickuptimes: PickUpTime[];
}

interface Info {
  customer: string;
  id: string;
  address: string;
  defaultPostalcode: string;
  warehouseLogic: WarehouseLogic;
  default_prices: {
    netPrice: boolean;
    salesPrice: boolean;
  };
  warehouses: Warehouse[];
}

const promises = {};

export const useCartStore = defineStore('cart', {
  state: () => ({
    info: {} as Info,
    data: {} as unknown,
    _cart: {
      list: {} as List,
      rows: [] as Row[],
    },
  }),
  actions: {
    async getInfo(refresh: boolean = false) {
      let info = this.info;
      if (!_.isEmpty(info) && !refresh) return info;

      if (!promises['getInfo'] || refresh) {
        promises['getInfo'] = getCartInfo()
          .then((data: any) => {
            return data.data;
          })
          .catch((err) => {
            console.error(err);
          });
      }
      if (promises['getInfo']) info = await promises['getInfo'];
      promises['getInfo'] = null;
      this.info = info;
      return info;
    },

    // Used to be just 'info' but that causes conflicts in Pinia
    setInfo(info: Info) {
      this.info = info;
    },
    setCart(cart: { list: List; rows: Row[] }) {
      this._cart = cart;
    },
    addItems(items: Row[]) {
      const rows = this._cart.rows;
      items.forEach((item) => {
        const row = rows?.find(
          (row) => row.article_id === item.article_id && row.unit === item.unit && !row.batchnr && !item.batchnr
        );
        if (row) {
          row.amount = Number(row.amount) + Number(item.amount);
        } else {
          rows.push(item);
        }
      });
    },
    setWarehouse(warehouse: string) {
      if (!warehouse) return; // Is there any reason we should ever set warehouse to nothing?
      if (this._cart?.list) this._cart.list.warehouse = warehouse;
      setListWarehouse(this.info.id, warehouse);
    },
    inCart(artnr, batchnr) {
      const matchArtnr = (row) => !artnr || row.artnr === artnr;
      const matchBatchnr = (row) => !batchnr || row.batchnr === batchnr;
      return this._cart.rows.find((row) => matchArtnr(row) && matchBatchnr(row));
    },
  },
  getters: {
    customerNumber(state) {
      return state.info ? state.info.customer : undefined;
    },
    addressId(state) {
      return state.info ? state.info.address : null;
    },
    default_price_visibility(state) {
      return state.info ? state.info.default_prices : null;
    },
    cart(state) {
      return validateCart(state._cart);
    },
    total(state): number {
      return (state._cart?.rows || []).reduce((acc, row) => {
        return acc + Math.ceil(row.amount);
      }, 0);
    },
    usePostalcodeWarehouseLogic(state) {
      return state.info?.warehouseLogic !== undefined ? state.info.warehouseLogic === 'postalcode' : undefined;
    },
    id(state) {
      return state.info?.id;
    },
    warehouseId(state) {
      return state._cart?.list?.warehouse;
    },
    warehouseName(state) {
      return state.info?.warehouses?.find((warehouse) => warehouse.id === state._cart?.list?.warehouse)?.label;
    },
    categorizedWarehouses(state): Record<string, string[]> {
      return state.info.warehouses.reduce((obj, warehouse) => {
        // BEN-1470: Disable WEBER specific rules
        // Note: After upgrade to Pinia, rootGetters is no longer available,
        // so if this need to be re-enabled, we need to import 'isWeberUser' into state
        // if (warehouse.itemAssortment === 'WEBER' && !rootGetters['account/isWeberUser']) return obj // Skip if 'WEBER' and not Weber user

        for (let category of warehouse.categories) {
          if (!obj[category]) obj[category] = [];
          obj[category].push(warehouse.id);
        }
        return obj;
      }, {});
    },
    // inCart(state) {
    //   return (artnr, batchnr) => {
    //     const matchArtnr = (row) => !artnr || row.artnr === artnr;
    //     const matchBatchnr = (row) => !batchnr || row.batchnr === batchnr;
    //     return state._cart.rows.find((row) => matchArtnr(row) && matchBatchnr(row));
    //   };
    // },
  },
});
