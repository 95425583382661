import type { App } from 'vue';

import '@/polyfill';
import router from './router';
import Cookie from 'js-cookie';

export default function init(app: App<Element>) {
  app.directive('click-outside', {
    beforeMount: function (el, binding) {
      router.afterEach(() => {
        // const vm = binding.instance;
        // vm?.[binding.value]();
        binding.value();
      });

      el.clickOutsideEvent = function (event) {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          // const vm = binding.instance;
          // vm?.[binding.value](event);
          if (typeof binding.value !== 'function') {
            console.log('click-outside value is not a function', binding.value);
          }
          binding.value?.();
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted: function (el) {
      document.body.removeEventListener('click', el.clickOutsideEvent);
    },
  });

  app.directive('click-inside', {
    beforeMount: function (el, binding) {
      router.afterEach(() => {
        // const vm = binding.instance;
        // vm?.[binding.value]();
        binding.value();
      });

      el.clickInsideEvent = function (event) {
        // here I check that click was inside the el and his children
        if (el == event.target || el.contains(event.target)) {
          // and if it did, call method provided in attribute value
          // const vm = binding.instance;
          // vm?.[binding.value](event);
          binding.value(event);
        }
      };
      document.body.addEventListener('click', el.clickInsideEvent);
    },
    unmounted: function (el) {
      document.body.removeEventListener('click', el.clickInsideEvent);
    },
  });

  app.directive('wp-href', {
    mounted: function (el, binding) {
      let nonceify = 'yes';
      if (binding.arg === 'withoutnonce') {
        nonceify = 'no';
      }
      const cookie = Cookie.get('benders_logged_in');
      const link = `${
        import.meta.env.VITE_APP_WP_API_BASE_URL
      }benders/v1/redirect/?nonceify=${nonceify}&url=${encodeURIComponent(binding.value)}&token=${cookie}`;
      el.href = link;
    },
  });

  app.directive('fetch-href', {
    mounted: function (el, binding) {
      let link = binding.value;
      let filename = el.getAttribute('filename');
      if (binding.arg !== 'withouttoken') {
        const cookie = Cookie.get('benders_logged_in');
        link += `?token=${cookie}`;
      }

      el.href = '';
      el.addEventListener('click', async function (e) {
        e.preventDefault();
        let response = await fetch(link);
        if (response.status === 200) {
          let data = await response.json();
          if (filename) {
            // Sketchy download...
            const fileResp = await fetch(data);
            const blob = await fileResp.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            window.location.href = data;
          }
        }
      });
    },
  });

  app.directive('min-quantity', {
    beforeMount(el, binding) {
      const min = binding.value;
      const allowDecimals = binding.arg === 'decimals';

      const updateWithDecimals = function (val) {
        if (val.endsWith('.') || val.endsWith(',')) {
          return;
        }

        val = val.replace(',', '.');
        val = parseFloat(val);
        let newVal = val && val >= min ? val : '';
        el.value = ('' + newVal).replace('.', ',');
      };

      const updateWithoutDecimals = function (val) {
        val = parseInt(val);
        let newVal = val && val >= min ? val : '';
        el.value = newVal;
      };

      el.updateEvent = function (val) {
        if (allowDecimals) {
          updateWithDecimals(val);
        } else {
          updateWithoutDecimals(val);
        }
      };

      el.addEventListener('change', function (e) {
        el.updateEvent(e.target.value);
      });

      el.addEventListener('input', function (e) {
        el.updateEvent(e.target.value);
      });
    },
  });

  app.directive('button-loader', {
    updated(el, binding) {
      // Add check if should update
      const enabled = binding.value;
      const type = binding.arg;

      // Create animation element if not exists
      let anim = el.querySelector('#button-loader-animation');
      if (!anim) {
        anim = document.createElement('div');
        anim.id = 'button-loader-animation';
        anim.classList.add('is-hidden', type);
        anim.style.position = 'absolute';
        el.appendChild(anim);
      }

      if (enabled) {
        el.classList.add('font-is-invisible');
        anim.classList.remove('is-hidden');
      } else {
        anim.classList.add('is-hidden');
        el.classList.remove('font-is-invisible');
      }
    },
  });

  app.directive('click-animation', {
    beforeMount(el, binding) {
      const type = binding.arg;
      const enabled = binding.value !== false;

      el.clickAnimationEndEvent = function (e) {
        if (e.animationName === type) {
          el.classList.remove(type);
          el.removeEventListener('animationend', el.clickAnimationEndEvent);
        }
      };

      el.clickAnimationEvent = function () {
        el.classList.add(type);
        el.addEventListener('animationend', el.clickAnimationEndEvent);
      };

      if (enabled) {
        el.addEventListener('click', el.clickAnimationEvent);
      } else {
        el.removeEventListener('click', el.clickAnimationEvent);
      }
    },
    updated(el, binding) {
      const enabled = binding.value !== false;
      if (enabled) {
        el.addEventListener('click', el.clickAnimationEvent);
      } else {
        el.removeEventListener('click', el.clickAnimationEvent);
      }
    },
    unmounted(el) {
      el.removeEventListener('click', el.clickAnimationEvent);
    },
  });

  app.directive('disabled', {
    updated(el, binding) {
      const disabled = binding.value;
      if (disabled) {
        el.setAttribute('disabled', 'true');
        el.classList.add('disabled');
      } else {
        el.removeAttribute('disabled');
        el.classList.remove('disabled');
      }
    },
  });
}
