import { createRouter, createWebHistory } from 'vue-router';
import { useHistoryStore } from '@/stores/history';
import { useAccountStore } from '@/stores/account';
import axios from 'axios';
import { getBearerToken } from '@/helpers/auth';

// import store from '@/store';

function nestRoutes(parent, routes) {
  return routes.map((route) => {
    if (parent.path) {
      route.path = parent.path + '/' + route.path;
      if (route.alias) {
        route.alias = parent.path + '/' + route.alias.replace(/^\//, '');
      }
    }
    if (parent.meta) {
      route.meta = {
        name: route.name.toLowerCase(),
        singleView: route.path + '/',
        ...parent.meta,
        ...route.meta,
      };
      route.meta.parentPath = parent.path + '/' + route.meta.parent;
    }
    return route;
  });
}

const routes = {
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/400',
      name: 'Error400',
      component: () => import('@/views/400.vue'),
      meta: {
        title: '400 - Benders Kundportal',
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'Error404',
      component: () => import('@/views/404.vue'),
      meta: {
        title: '404 - Benders Kundportal',
      },
    },
    {
      path: '/403',
      name: 'Unauthorized403',
      component: () => import('@/views/403.vue'),
      meta: {
        title: '403 - Benders Kundportal',
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login.vue'),
      meta: {
        header: false,
        footer: false,
        title: 'Benders Kundportal - Logga in',
        public: true,
      },
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import('@/views/Login.vue'),
      meta: {
        header: false,
        footer: false,
        title: 'Benders Kundportal - Loggas ut',
        public: true,
      },
    },
    {
      path: '/',
      name: 'Root',
      redirect: '/mina-sidor',
    },
    ...nestRoutes(
      {
        path: '/mina-sidor',
        name: 'Mina Sidor',
        meta: {
          nav_account: true,
          title: 'Benders Kundportal',
          scroll: 'always',
        },
      },
      [
        {
          path: 'orders',
          alias: '/',
          name: 'Orders',
          component: () => import('@/views/myaccount/Orders.vue'),
        },
        {
          path: 'quotes',
          name: 'Quotes',
          component: () => import('@/views/myaccount/Quotes.vue'),
        },
        {
          path: 'tickets',
          name: 'Tickets',
          component: () => import('@/views/myaccount/Tickets.vue'),
        },
        {
          path: 'lists',
          name: 'Lists',
          component: () => import('@/views/myaccount/Lists.vue'),
        },
        // {
        //   path: 'weber',
        //   name: 'Weber',
        //   component: Weber,
        // },
        {
          path: 'prices',
          name: 'Prices',
          redirect: '/prices',
        },
        {
          path: 'account',
          name: 'Account',
          component: () => import('@/views/myaccount/Account.vue'),
        },
        {
          path: 'reststock',
          name: 'RestStock',
          component: () => import('@/views/myaccount/RestStock.vue'),
          meta: {
            employeeOrMucOnly: true,
            notInRegion: ['FIN', 'KRI'],
          },
        },
        {
          path: 'addresses',
          name: 'Addresses',
          component: () => import('@/views/myaccount/Addresses.vue'),
        },
        {
          path: 'users',
          name: 'Users',
          component: () => import('@/views/myaccount/Users.vue'),
        },
      ]
    ),
    ...nestRoutes(
      {
        path: '/mina-sidor',
        name: 'Mina Sidor',
        meta: {
          nav_account: true,
          title: 'Benders Kundportal',
          scroll: 'always',
        },
      },
      [
        {
          path: 'orders/:id/:subView?/:subId?',
          name: 'Order',
          component: () => import('@/views/myaccount/single/order/Order.vue'),
          meta: {
            parent: 'orders',
            ignoreHistory: true,
            scroll: 'different-parent',
          },
        },
        {
          path: 'quotes/C:id',
          name: 'Customer Quote',
          component: () => import('@/views/myaccount/single/CustomerQuote.vue'),
          meta: {
            parent: 'quotes',
          },
        },
        {
          path: 'quotes/C:id/edit',
          name: 'Customer Quote Wizard',
          component: () => import('@/views/myaccount/single/CustomerQuote.vue'),
          meta: {
            parent: 'quotes',
            CQWizard: true,
          },
        },
        {
          path: 'quotes/:id',
          name: 'Quote',
          component: () => import('@/views/myaccount/single/Quote.vue'),
          meta: {
            parent: 'quotes',
          },
        },
        {
          path: 'tickets/:id',
          name: 'Ticket',
          component: () => import('@/views/myaccount/single/Ticket.vue'),
          meta: {
            parent: 'tickets',
          },
        },
        {
          path: 'lists/:id',
          name: 'List',
          component: () => import('@/views/myaccount/single/ShoppingList.vue'),
          meta: {
            parent: 'lists',
          },
        },
        {
          path: 'addresses/:id',
          name: 'Address',
          component: () => import('@/views/myaccount/single/Address.vue'),
          meta: {
            parent: 'addresses',
          },
        },
        {
          path: 'users/:id',
          name: 'User',
          component: () => import('@/views/myaccount/single/User.vue'),
          meta: {
            parent: 'users',
          },
        },
      ]
    ),
    {
      path: '/produktsortiment/:category',
      alias: '/produktkategori/:category',
      name: 'Products',
      component: () => import('@/views/Products.vue'),
      meta: {
        title: 'Benders Kundportal',
        scroll: 'always',
      },
    },
    {
      path: '/produkt/:product_id',
      name: 'Product',
      component: () => import('@/views/Product.vue'),
      meta: {
        title: 'Benders Kundportal',
        scroll: 'always',
      },
    },
    {
      path: '/checkout/',
      name: 'Checkout',
      component: () => import('@/views/Checkout.vue'),
      meta: {
        title: 'Benders Kundportal',
        scroll: 'always',
        hideCart: true,
        isCheckout: true,
      },
    },
    ...(!import.meta.env.VITE_APP_CHECKOUT_V2_ENABLED
      ? []
      : [
          {
            path: '/checkout2/:orderNumber?',
            name: 'Checkout2',
            component: () => import('@/views/CheckoutV2.vue'),
            meta: {
              title: 'Benders Kundportal',
              scroll: 'always',
              hideCart: true,
              isCheckout: true,
            },
          },
        ]),
    {
      path: '/warehouse/:id',
      name: 'Warehouse',
      component: () => import('@/views/Warehouse.vue'),
      meta: {
        title: 'Benders Kundportal',
        scroll: 'always',
      },
    },
    {
      path: '/calculation/:view/:id?',
      name: 'Calculation',
      component: () => import('@/views/Calculation.vue'),
      meta: {
        title: 'Benders Kundportal',
        scroll: 'always',
        basePath: '/calculation/',
      },
    },
    {
      path: '/prices',
      name: 'Price lists',
      component: () => import('@/views/Prices.vue'),
      meta: {
        title: 'Benders Kundportal',
        scroll: 'always',
      },
    },
    {
      path: '/act-as-other-user',
      name: 'ActAsOtherUser',
      component: () => import('@/views/ActAsOtherUser.vue'),
      meta: {
        title: 'Agera som användare',
        scroll: 'always',
      },
    },
    {
      path: '/search',
      name: 'Search',
      component: () => import('@/views/Search.vue'),
      meta: {
        title: 'Sök',
        scroll: 'always',
      },
    },
    {
      path: '/wp-page/:slug',
      name: 'Wordpress Page',
      component: () => import('@/views/WpPage.vue'),
      meta: {
        title: 'Benders Kundportal',
        scroll: 'always',
        public: true,
      },
    },
    {
      path: '/lost-password/',
      name: 'Lost password',
      component: () => import('@/views/LostPassword.vue'),
      meta: {
        title: 'Benders Kundportal',
        scroll: 'always',
        public: true,
      },
    },
    {
      path: '/register/',
      name: 'Register',
      component: () => import('@/views/Register.vue'),
      meta: {
        title: 'Benders Kundportal',
        scroll: 'always',
        public: true,
      },
    },
    {
      path: '/noCustomerAccount/',
      name: 'NoCustomerAccount',
      component: () => import('@/views/NoCustomerAccount.vue'),
      meta: {
        title: 'Benders Kundportal',
        scroll: 'always',
        public: true,
      },
    },
  ],
};

export const router = createRouter(routes);

router.beforeEach((to, from, next) => {
  const account = useAccountStore();

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const success = () => {
    const history = useHistoryStore();
    const previous = history.previous;
    const fullPath = window.location.pathname + window.location.search;
    if (previous == to.fullPath) history.pop();
    if (to.fullPath != fullPath && previous != to.fullPath && !(from.meta.ignoreHistory && to.meta.ignoreHistory))
      history.push(fullPath);

    if (nearestWithTitle) document.title = nearestWithTitle.meta.title as string;

    next();
  };

  // const publicPages = ['/login', '/register'];
  // const authRequired = !publicPages.includes(to.path);
  const employeeOrMucOnly = nearestWithTitle?.meta.employeeOrMucOnly;
  const authRequired = !nearestWithTitle?.meta.public;
  const notInRegion = (nearestWithTitle?.meta.notInRegion ?? []) as string[];
  const isCheckout = nearestWithTitle?.meta.isCheckout;
  const user = account.user;
  const bearerToken = getBearerToken();
  const loggedIn = user && bearerToken;
  if (authRequired && !loggedIn) {
    // If cookies exists but not the session user, re-auth
    if (bearerToken) {
      axios
        .get(`${import.meta.env.VITE_APP_AUTH_API_BASE_URL}session`, {
          headers: {
            Authorization: bearerToken,
          },
        })
        .then((r) => {
          account.loginSuccess(r.data, bearerToken, r.data.region); // Update state
          success(); // Successfully re-authed
        })
        .catch(() => {
          next({ path: '/login', query: { returnurl: to.fullPath } }); // Failed to auth cookie
        });
    } else {
      next({ path: '/login', query: { returnurl: to.fullPath } }); // No token to authenticate
    }
    // Add check for MUC users as well, not sure how to check the current customer so just ensure one available customer is MUC
  } else if (
    employeeOrMucOnly &&
    !account.isBendersEmployee &&
    !account.userCustomers.some((c) => c.customerGroup == 'MUC')
  ) {
    next('/403');
  } else if (notInRegion.includes(account.region)) {
    next('/403');
  } else if (isCheckout && account.user?.purchasableAssortments?.length == 0) {
    next('/403');
  } else {
    success(); // No auth required / Logged in
  }
});

router.afterEach((to, from) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const shouldScrollDifferentParent = nearestWithTitle?.meta?.scroll === 'different-parent';
  const shouldScroll = nearestWithTitle?.meta?.scroll === 'always';
  // if(nearestWithTitle && nearestWithTitle.meta && nearestWithTitle.meta.scrollToTop) window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

  let previousPos;
  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if ((c > 0 && c <= previousPos) || previousPos == null) {
      previousPos = c;
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, Math.max(0, c - Math.max(10, c / 8)));
    }
  };

  if (from.path != to.path && shouldScroll) scrollToTop();
  if (from.meta.parent != nearestWithTitle?.meta.parent && shouldScrollDifferentParent) scrollToTop();
});

router.onError((error, to) => {
  if (
    error.message.includes('dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    window.location.href = to.fullPath;
  }
});

export default router;
