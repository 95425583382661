import type { ProductlistPrices } from '@/types/Productlist';

import { Emittable } from '@/data/productlist/generic.ts';
import {
  cancelRequest,
  getLinePrice,
  getPriceV2 as getPrice,
  PriceConfig,
  LinePriceConfig,
} from '@/data/api/products/requests';
import { getArticleBaseUnitAmount, formatPrice, roundUnit } from '@/data/api/products/helpers';
import { mapState } from 'pinia';

import _ from 'lodash';
import { useCartStore } from '@/stores/cart';
import { useAccountStore } from '@/stores/account';

/* Price module
  @Requires
    - Articles

  @Variables(Mutable)
    customer_number: string
    address_id: string
*/
export class Price extends Emittable {
  cache: Record<string, any>;
  fetch_price: boolean;
  fetch_line_price: boolean;
  fetch_both: boolean;
  automaticCurrency: boolean;
  usePayerRegion: boolean;
  constructor({
    price = true,
    linePrice = true,
    priceVisibility = 'salesPrice',
    currency = 'SEK',
    fetchSalesAndNet = false,
    automaticCurrency = false,
    usePayerRegion = false,
  }: {
    price?: boolean;
    linePrice?: boolean;
    priceVisibility?: string | null;
    currency?: string;
    fetchSalesAndNet?: boolean;
    automaticCurrency?: boolean;
    usePayerRegion?: boolean;
  } = {}) {
    super();
    const self = this;
    this.cache = {};
    this.fetch_price = price;
    this.fetch_line_price = linePrice;
    this.fetch_both = fetchSalesAndNet;
    this.automaticCurrency = automaticCurrency;
    this.usePayerRegion = usePayerRegion;
    this.mixin = {
      data() {
        return {
          c_price_visibility: ['netPrice', 'salesPrice', null].includes(priceVisibility)
            ? priceVisibility
            : 'salesPrice',
          c_price_currency: currency,
          c_prices: {} as Record<string, ProductlistPrices>,
        };
      },
      computed: {
        ...mapState(useCartStore, ['default_price_visibility']),
        ...mapState(useAccountStore, ['isBendersEmployee']),
        c_activePrice: {
          set(val) {
            self.cache = {}; // Clear cache, to allow for recalculation
            (this as any).c_price_visibility = ['netPrice', 'salesPrice', null].includes(val) ? val : 'salesPrice';
          },
          get() {
            if ((this as any).isBendersEmployee && !(this as any).p_c_customer_number) return 'salesPrice';
            return (this as any).c_price_visibility;
          },
        },
      },
      watch: {
        p_c_rows: {
          deep: true,
          handler(rows) {
            self._getPrices.call(self, this, rows);
          },
        },
        payer: {
          deep: true,
          handler(payer) {
            if (self.usePayerRegion && payer?.priceListTable) {
              self._getPrices.call(self, this, (this as any).p_c_rows);
            }
          },
        },
        p_c_customer_number(newValue, oldValue) {
          if (!newValue || newValue === oldValue) return;
          (this as any).c_prices = {};
          self._getPrices.call(self, this, this.p_c_rows);
        },
        p_c_address_id(newValue, oldValue) {
          if (newValue === oldValue) return;
          (this as any).c_prices = {};
          self._getPrices.call(self, this, this.p_c_rows);
        },
        default_price_visibility: {
          immediate: true,
          handler(prices) {
            if ((this as any).c_price_visibility === null && (prices?.netPrice || prices?.salesPrice)) {
              (this as any).c_price_visibility = prices?.netPrice ? 'netPrice' : 'salesPrice';
            }
          },
        },
      },
    };
  }

  init() {
    this.cache = {};

    this.on('price:currency', this.setCurrency.bind(this));
  }

  itemHasChanged(key, item) {
    if (!(key in this.cache)) {
      this.cache[key] = item;
      return true;
    }

    if (!_.isEqual(this.cache[key], item)) {
      this.cache[key] = item;
      return true;
    }
    return false;
  }

  calcAmount(row) {
    return roundUnit(
      row.amount * (row?.article?.unit?.[row.unit || 'st'] || 0) || row.amount,
      row.article.numberOfDecimalPlaces,
      false
    );
  }

  _getPrices = _.debounce(this.getPrices, 100);
  async getPrices(vm, rows) {
    if (!rows) return rows;

    let payerRegion;
    if (this.usePayerRegion) {
      payerRegion = vm.payer?.priceListTable;
    }

    // Check data against cache
    const data = rows.reduce((acc, row) => {
      if (row.article && row.article.ArtNr) {
        const item = {
          id: row.id,
          artnr: row.article.ArtNr,
          amount: this.calcAmount(row),
          unit: row.unit,
          warehouse: row.warehouse,
          region: payerRegion,
          addon: row?._meta?.addon || false,
        };
        if (!this.itemHasChanged(item.id, item) && row.id in vm.c_prices) {
          return acc;
        }
        acc.push(item);
      }
      return acc;
    }, []);

    await this.store('cart', 'getInfo')?.();

    // Get price
    if (this.store('debug', 'debugMode')) console.debug('Fetching price for items', data);
    for (let item of data) {
      // Set the id in prices so it doesn't get fetched again while we wait for the price
      if (!(item.id in vm.c_prices)) vm.c_prices[item.id] = null;
      this.fetchPrice(vm, item);
    }
  }

  async fetchPrice(vm, item) {
    const customer_number = vm.p_c_customer_number;
    const address_id = vm.p_c_address_id;
    const is_custom_address = vm.c_selected_stock_address === 'new_address';
    let payerRegion;
    if (this.usePayerRegion) {
      payerRegion = vm.payer?.priceListTable;
    }

    const id = item.id;
    const artnr = item.artnr;
    const amount = item.amount;
    const warehouse = item.warehouse;

    // Check if we have all the required data
    if (!artnr || (!address_id && !is_custom_address) || (this.usePayerRegion && !payerRegion)) {
      if (this.store('debug', 'debugMode')) {
        console.debug('Missing required data for price fetch', {
          artnr,
          address_id,
          warehouse,
        });
      }
      return;
    }

    let prices = {
      price: {} as any,
      price_sales: {} as any,
      price_net: {} as any,
      priceM2: null,
      line_price: {} as any,
      needsQuote: false,
      retries: vm.c_prices[id]?.retries || 0,
    };

    let priceError = false;

    if (this.fetch_price && !this.fetch_both) {
      cancelRequest.call({ _uid: id }, 'price');
      await getPrice
        .call({ _uid: id }, {
          artnr: artnr,
          address: address_id,
          customerNumber: customer_number,
          warehouse: warehouse,
          region: payerRegion,
        } as PriceConfig)
        .then((price: any) => {
          if (_.isEmpty(price?.netPrice) && _.isEmpty(price?.salesPrice)) {
            prices.needsQuote = true;
            return;
          }

          const p = formatPrice(price, vm.c_activePrice, amount, { asObject: true });
          prices.price = p;
          prices.priceM2 = price?.m2;
        })
        .catch((err) => {
          console.error(err);
          priceError = err.response?.status !== 403;
        });
    }

    // Use new v2/price endpoint and fetch both sales and net price
    if (this.fetch_price && this.fetch_both) {
      cancelRequest.call({ _uid: id }, 'pricev2');
      await getPrice
        .call({ _uid: id }, {
          artnr: artnr,
          address: address_id,
          customerNumber: customer_number,
          warehouse: warehouse,
          region: payerRegion,
        } as PriceConfig)
        .then((data: any) => {
          if (_.isEmpty(data?.netPrice)) {
            prices.needsQuote = true;
            return;
          }

          const salesP = formatPrice(data?.salesPrice, 'salesPrice', amount, {
            asObject: true,
          });
          const netP = formatPrice(data?.netPrice, 'netPrice', amount, { asObject: true });
          const p = vm.c_activePrice == 'salesPrice' ? salesP : netP;
          prices.price = p;
          prices.price_sales = salesP;
          prices.price_net = netP;
          prices.priceM2 = data?.m2;
        })
        .catch((err) => {
          console.error(err);
          priceError = err.response?.status !== 403;
        });
    }

    if (!priceError && this.fetch_line_price) {
      // console.debug("Fetching line price", artnr, address_id, customer_number)
      cancelRequest.call({ _uid: id }, 'linePrice');
      prices.line_price = await getLinePrice
        .call({ _uid: id }, {
          artnr: artnr,
          address: address_id,
          customerNumber: customer_number,
          quantity: amount,
          warehouse: warehouse,
          region: payerRegion,
        } as LinePriceConfig)
        .then((data) => {
          return data?.price;
        })
        .catch((err) => {
          console.error(err);
          priceError = err.response?.status !== 403;
        });
    }

    if (priceError) {
      prices = (await this.backoff(prices)) as any;
      delete this.cache[id];
    }

    if (!_.isEqual(vm.c_prices[id], prices)) {
      vm.c_prices[id] = prices;
    }

    if (this.automaticCurrency && vm.c_price_currency !== prices.price?.currency) {
      this.setCurrency(vm, prices.price?.currency);
    }
  }

  backoff(prices) {
    return new Promise((resolve) => {
      let timeout = Math.min(30, Math.pow(2, prices.retries)) * 1000;
      prices.retries = (prices.retries || 0) + 1;
      if (timeout == 0) {
        resolve(prices);
      }
      setTimeout(() => {
        resolve(prices);
      }, timeout);
    });
  }

  modify(vm, rows) {
    if (this.store('debug', 'debugMode')) console.debug('Price modifying', rows);
    const mapped = rows.map((row) => {
      let article = row.article;
      if (article && row.id in vm.c_prices && vm.c_prices[row.id]) {
        const c_price = vm.c_prices[row.id];
        if (c_price.needsQuote) {
          row.article.needsQuote = true;
          row.needsQuote = true;
          return row;
        }

        // Make sure we have a baseunitAmount (Should be set by articles.ts, but just in case)
        if (!row.baseunitAmount || row._meta?.addon) {
          row.baseunitAmount = getArticleBaseUnitAmount(article, row);
        }

        // BEN-1607: If the price is in t and the unit is kg, convert baseunitAmount to tons
        if (c_price.price?.salesPriceUnitOfMeasure === 't' && row.unit === 'kg') {
          row.baseunitAmount.value = row.baseunitAmount.value / 1000;
        } else if (c_price.price?.salesPriceUnitOfMeasure === 'kg' && row.unit === 't') {
          row.baseunitAmount.value = row.baseunitAmount.value * 1000;
        }

        c_price.price.price = vm.c_activePrice in c_price.price ? c_price.price[vm.c_activePrice] : 0;
        c_price.price.price_total =
          vm.c_activePrice === 'netPrice'
            ? c_price.line_price?.lineAmount
            : c_price.price.price * row.baseunitAmount.value;

        if (this.fetch_both) {
          c_price.price_sales.price = c_price.price_sales.salesPrice;
          c_price.price_sales.price_total = c_price.price_sales.salesPrice * row.baseunitAmount.value;
          c_price.price_net.price = c_price.price_net.netPrice;
          c_price.price_net.price_total = c_price.line_price?.lineAmount;
        }

        if (c_price.priceM2) {
          const priceVisiblity = vm.c_activePrice === 'netPrice' ? 'lineAmount' : 'salesAmount';
          c_price.priceM2.price = priceVisiblity in c_price.priceM2 ? c_price.priceM2[priceVisiblity] : 0;
        }

        // Set price on article
        article.price = c_price.price;
        article.price_sales = c_price.price_sales;
        article.price_net = c_price.price_net;
        article.priceM2 = c_price.priceM2;
        article.line_price = c_price.line_price;
      }

      // Validate prices
      if (_.isNaN(article.price?.price ?? NaN)) article.price = { ...article.price, price: '...' };
      if (_.isNaN(article.price?.price_total ?? NaN)) article.price = { ...article.price, price_total: '...' };
      if (_.isNaN(article.line_price?.lineAmount ?? NaN))
        article.line_price = { ...article.line_price, lineAmount: '...' };

      // If addon row, overwrite the unit if it hasn't been set
      if (row._meta?.addon) {
        row.unit = row.unit || article.price?.salesPriceUnitOfMeasure || 'st';
        row.salesUnit = row.unit;
      }

      return row;
    });

    return mapped;
  }

  setCurrency(vm, currency) {
    if (currency === vm.c_price_currency || !currency) return;

    vm['c_price_currency'] = currency;
    this.cache = {}; // Clear cache, to allow for recalculation
  }
}
