import { createI18n } from 'vue-i18n';
import enUS from './en_US.json';
import svSE from './sv_SE.json';
import fiFI from './fi_FI.json';
import nbNO from './nb_NO.json';
import deDE from './de_DE.json';

export const supportedLocales = ['en-US', 'sv-SE', 'fi-FI', 'nb-NO', 'de-DE'];

const i18n = createI18n({
  legacy: false,
  locale: 'sv-SE',
  fallbackLocale: 'sv-SE',
  supportedLocales: supportedLocales,
  globalInjection: true,
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    'en-US': enUS,
    'sv-SE': svSE,
    'fi-FI': fiFI,
    'nb-NO': nbNO,
    'de-DE': deDE,
  },
});

export default i18n;
