import i18n from '@/languages/i18n';

export const getMappedRoleTranslations = () => {
  return {
    1: i18n.global.t('user.role.system_admin'),
    2: i18n.global.t('user.role.benders_admin'),
    3: i18n.global.t('user.role.benders_user'),
    4: i18n.global.t('user.role.customer_admin'),
    5: i18n.global.t('user.role.customer_user'),
    6: i18n.global.t('user.role.not_assigned'),
  };
};
