import { defineStore } from 'pinia';
// import { useStorage } from '@vueuse/core';

// const jsonSerializer = {
//   read: (v: any) => (v ? JSON.parse(v) : null),
//   write: (v: any) => JSON.stringify(v),
// };

export const useHistoryStore = defineStore('history', {
  state: () => ({
    history: [] as string[],
    reloads: 0,
  }),
  actions: {
    push(location) {
      this.history.push(location);
    },
    pop() {
      this.history.pop();
    },
    reload() {
      this.reloads++; // Used as key for main router to force reloads
    },
  },
  getters: {
    previous(state): string | null {
      return state.history[this.history.length - 1] || null;
    },
    previousPath(state): string | null {
      const current = window.location.pathname;
      for (let i = state.history.length - 1; i >= 0; i--) {
        const old = state.history[i];
        if (old && !old.match(current)) return old;
      }
      return null;
    },
  },
});
