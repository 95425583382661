import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

import { getWarehouses } from '@/data/api/kundportal/requests';

const jsonSerializer = {
  read: (v: any) => (v ? JSON.parse(v) : null),
  write: (v: any) => JSON.stringify(v),
};

const promises = {};

export const usePostalcodeStore = defineStore('postalcode', {
  state: () => ({
    _postalcode: useStorage<string>('benders_postalcode', '', undefined, {
      serializer: jsonSerializer,
    }),
    warehouseLocation: {},
    warehouses: {} as Record<string, unknown[]>,
  }),
  actions: {
    setWarehouseLocation({ artnr, warehouse }: { artnr: string; warehouse: unknown }) {
      if (!artnr || !warehouse) return;
      this.warehouseLocation[artnr] = warehouse;
    },
    async getWarehouses(region: string = 'default', force = false) {
      let warehouses = [] as unknown[];
      if (!force) {
        warehouses = this.warehouses?.[region] ?? [];
        if (warehouses.length) return warehouses;
      }
      if (!promises[region]) {
        promises[region] = getWarehouses('info', '', region !== 'default' ? region : '').then((data: any) => {
          return data?.data;
        });
      }
      if (promises[region]) {
        warehouses = await promises[region];
      }
      promises[region] = null;
      this.warehouses[region] = warehouses;
      return warehouses;
    },
    setPostalcode(postalcode: string) {
      this._postalcode = postalcode;
      this.warehouseLocation = {}; // Reset warehouse location
    },
    setWarehouses(region: string, warehouses: unknown[]) {
      this.warehouses[region] = warehouses;
    },
  },
  getters: {
    postalcode(state) {
      if (!state._postalcode || typeof state._postalcode !== 'string') return '';
      return state._postalcode.replace(/\s/g, '').replace(/\D/g, '');
    },
  },
});
