<template>
  <div
    v-if="user && allowed"
    :class="['debug-inspector', expanded ? 'expanded' : '']"
    :style="{ width: !expanded && debugMode ? '240px' : undefined }"
  >
    <div class="header" @click.prevent="expanded = !expanded">
      <span v-if="debugMode">
        {{ $t('debug.debug') }}
        <b class="has-text-danger">({{ $t('debug.debug_mode') }})</b>
      </span>
      <span v-else>{{ $t('debug.debug') }}</span>
      <a>
        <span class="is-size-6 icon">
          <font-awesome-icon :icon="['far', expanded ? 'chevron-down' : 'chevron-up']" />
        </span>
      </a>
    </div>
    <div class="inspector-content">
      <div>
        <h2 style="white-space: nowrap">{{ $t('debug.debug_tools') }}</h2>
        <div style="display: flex; flex-direction: column">
          <!-- Debug mode switch -->
          <div class="field">
            <input
              id="debugMode"
              type="checkbox"
              name="debugMode"
              class="switch is-rtl is-small is-outlined is-danger"
              v-model="debugMode"
            />
            <label for="debugMode">{{ $t('debug.debug_mode') }}</label>
          </div>
        </div>
        <a @click.prevent="debugState()">{{ $t('debug.log_state_to_console') }}</a>
        <label class="margin-xxsmall-top">
          {{ $t('debug.debug') }}
          <select v-model="debugEntity">
            <option v-for="entity in debugEntities" :value="entity.key" :key="entity.key">{{ entity.label }}</option>
          </select>
          :
          <div>
            <input
              type="text"
              ref="artdebug"
              @keyup.enter="debugEntityHandler(($refs.artdebug as HTMLInputElement).value)"
              placeholder="Artnr/ID (ex. 020020)"
            />
            <button @click="debugEntityHandler(($refs.artdebug as HTMLInputElement).value)">></button>
            <br />
            <i class="is-size-7">{{ $t('debug.check_console_for_results') }}</i>
          </div>
        </label>
        <div>
          <label>
            {{ $t('debug.last_product_sync') }}:
            <div style="display: flex; flex-direction: column">
              <span class="is-size-7">
                {{ $t('debug.full_sync') }} - {{ lastSync?.full_sync ? lastSync.full_sync : $t('debug.not_run') }}
              </span>
              <span class="is-size-7">
                {{ $t('debug.delta_sync') }} - {{ lastSync?.delta_sync ? lastSync.delta_sync : $t('debug.not_run') }}
              </span>
            </div>
          </label>
        </div>
      </div>
      <div class="table-inspector">
        <div style="display: flex; justify-content: space-between">
          <h2>{{ $t('debug.components') }}</h2>
          <div>
            <a @click.prevent="update(0)">
              <span class="is-size-6 icon">
                <font-awesome-icon :icon="['fas', 'sync']" />
              </span>
            </a>
          </div>
        </div>
        <a @click.prevent="debug">{{ $t('debug.log_main_component_to_console') }}</a>
        <InspectionTable v-if="parent && expanded" :component="parent" class="scroller" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  // import _ from 'lodash'
  import { mapState, mapActions } from 'pinia';
  import InspectionTable from '@/components/debug-inspector/InspectionTable.vue';
  import { getDebugEntity } from '@/data/api/kundportal/requests';
  import { getArticlesByArtNr, getSLArticle, getLastSync } from '@/data/api/products/requests';
  import { useAccountStore } from '@/stores/account';
  import { useDebugStore } from '@/stores/debug';

  import pinia from '@/stores';

  export default {
    name: 'DebugInspector',
    components: {
      InspectionTable,
    },
    computed: {
      ...mapState(useAccountStore, ['user']),
      ...mapState(useDebugStore, {
        debugModeActive: 'debugMode',
      }),
      debugMode: {
        get() {
          return this.debugModeActive;
        },
        set(value) {
          this.setDebugMode(value);
        },
      },
    },
    data() {
      return {
        allowed: false,
        lastSync: {
          full_sync: null,
          delta_sync: null,
        },
        expanded: false,
        parent: null as any,
        debugEntity: 'Article',
        debugEntities: [
          { key: 'Article', label: this.$t('generic.articles') },
          { key: 'Order', label: this.$t('generic.orders') }, // Needs modified solution in backend
          { key: 'Offer', label: this.$t('generic.offer') }, // Needs modified solution in backend
          { key: 'Customer', label: this.$t('generic.customers') },
          { key: 'Case', label: this.$t('generic.tickets') },
          // { key: 'Address', label: this.$t('generic.addresses') }, // Needs a custom solution in backend
          { key: 'Contact', label: this.$t('generic.users') },
        ],
      };
    },
    methods: {
      ...mapActions(useAccountStore, ['userCan']),
      ...mapActions(useDebugStore, ['setDebugMode']),
      update(delay = 2000) {
        setTimeout(() => {
          this.parent = null;
          setTimeout(() => {
            this.parent = this.$parent;
          }, 100);
        }, delay);
      },
      debug() {
        console.dir(this.$parent);
      },
      debugState() {
        console.dir(pinia);
      },
      async debugEntityHandler(val) {
        console.debug(this.debugEntity);
        let data = null as any;

        try {
          if (this.debugEntity === 'Article') {
            data = await this.getArticle(val);
          } else {
            data = await this.getDebugEntity(this.debugEntity, val);
          }
        } catch (e) {
          console.error(e);
        }

        if (data !== null) (this.$refs.artdebug as any).value = '';
        (this.$refs.artdebug as any).disabled = false;
        console.dir(data);
      },
      async getArticle(artnr) {
        return new Promise((resolve, reject) => {
          if (!artnr) {
            reject(new Error('No artnr given'));
            return;
          }

          let data = {
            kundportalen: {} as any,
            tjanstelagret: {} as any,
          };

          (this.$refs.artdebug as any).disabled = true;

          const promises = [] as Promise<any>[];
          promises.push(
            getArticlesByArtNr([artnr], true)
              .then((articles) => {
                for (let key in articles) {
                  data.kundportalen = articles[key];
                }
              })
              .catch((err) => {
                console.error(err);
              })
          );

          promises.push(
            getSLArticle(artnr)
              .then((article) => {
                data.tjanstelagret = article;
              })
              .catch((err) => {
                console.error(err);
              })
          );

          Promise.all(promises)
            .then(() => {
              if (data.kundportalen && data.tjanstelagret?.status !== 404) {
                resolve(data);
              } else {
                reject(new Error('No data found'));
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      async getDebugEntity(entity, id) {
        return new Promise((resolve, reject) => {
          if (!id) {
            reject(new Error('No id given'));
            return;
          }
          getDebugEntity(entity, id)
            .then((data) => {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    },
    watch: {
      $route() {
        setTimeout;
        this.update();
      },
      user: {
        immediate: true,
        deep: true,
        handler() {
          if (!this.user) return;
          this.userCan('see_debug_bar')
            .then((allowed) => {
              this.allowed = allowed;
              if (allowed) {
                getLastSync().then((data) => {
                  this.lastSync = data;
                });
              }
            })
            .catch(() => {
              this.allowed = false;
            });
        },
      },
    },
    created() {
      this.update();
    },
  };
</script>

<style scoped>
  .debug-inspector {
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 130px;
    max-height: 34px;
    overflow: hidden;
    padding: 0 0 1em 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    border-radius: 0 6px 0 0;
  }

  .debug-inspector.expanded {
    max-height: 400px;
    width: 100%;
    border-radius: 0;
  }

  .debug-inspector > div {
    padding: 0 1em;
  }

  .debug-inspector .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25em 1em;
    margin-bottom: 0.5em;
    border-bottom: 2px solid #e2e3e4;
    cursor: pointer;
  }

  .inspector-content {
    display: flex;
    justify-content: space-between;
  }

  .inspector-content .scroller {
    max-height: 280px;
    overflow-y: auto;
    padding-right: 1em;
  }

  .inspector-content > div {
    display: flex;
    flex-direction: column;
  }

  .table-inspector {
    width: 100%;
    height: 100%;
    padding: 0 0 1em 1em;
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-size: 2em !important;
  }
</style>
