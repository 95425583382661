<template>
  <div
    id="cookie-banner"
    ref="banner"
    :class="['cookie-banner', !showCookieConsent ? 'cookie-banner--hidden' : null]"
    style=""
  >
    <div class="cookie-banner__inner">
      <div class="cookie-banner__info">
        <p>
          <i18n-t keypath="cookie_notification.text">
            <router-link class="privacy-policy-link" :to="'/wp-page/' + divisionLinks.privacyPolicy">
              {{ $t('generic.privacy_policy') }}
            </router-link>
          </i18n-t>
        </p>
      </div>

      <div class="cookie-banner__actions">
        <button class="cookie-banner__button" @click.prevent="consent">
          {{ $t('cookie_notification.consent_confirmation') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { mapState } from 'pinia';
  import Cookie from 'js-cookie';
  import { useTranslationStore } from '@/stores/translation';
  import { I18nT } from 'vue-i18n';

  export default {
    name: 'CookieNotification',
    data() {
      return {
        showCookieConsent: Cookie.get('cookie_consent') !== 'yes',
      };
    },
    methods: {
      consent() {
        Cookie.set('cookie_consent', 'yes', {
          expires: 28,
        });
        this.showCookieConsent = false;
      },
    },
    computed: {
      ...mapState(useTranslationStore, ['divisionLinks']),
    },
    mounted() {
      const banner = this.$refs.banner as HTMLElement;
      if (!this.showCookieConsent) {
        banner.style.display = 'none';
      } else {
        banner.addEventListener('transitionend', (e: any) => {
          if (e.target.id !== 'cookie-banner') return;
          banner.style.display = 'none';
        });
      }
    },
  };
</script>

<style>
  .cookie-banner {
    width: 100%;
    background-color: #a6192e;
    color: #fff;
    transition:
      transform 1s ease-out,
      opacity 1s ease-out;
  }
  .cookie-banner__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    max-width: 1280px;
    margin: auto;
    padding: 40px 0;
  }
  .cookie-banner__info {
    width: 66%;
  }
  .cookie-banner__info p,
  .cookie-banner__info p a {
    margin: 0;
    color: white;
  }
  .cookie-banner__info p a {
    font-weight: bold;
  }
  .cookie-banner__actions {
    padding-left: 20px;
  }
  .cookie-banner__button {
    display: inline-block;
    width: auto;
    overflow: visible;
    padding: 8px 40px;
    border: 2px solid #fff;
    background: 0 0;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;
    height: auto;
  }
  .cookie-banner--hidden {
    opacity: 0;
    transform: translateY(-100%);
  }
  .cookie-banner__button:hover,
  .cookie-banner__button:active {
    border-color: #fff;
    background: #fff;
    color: #58595b;
  }
  @media only screen and (max-width: 1260px) {
    .cookie-banner__inner {
      width: auto;
      margin: auto;
      padding: 20px;
    }
    .cookie-banner__info {
      width: 50%;
    }
  }
  @media only screen and (max-width: 900px) {
    .cookie-banner__inner {
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .cookie-banner__info {
      width: auto;
      margin-bottom: 20px;
    }
    .cookie-banner__actions {
      padding-left: 0;
      text-align: center;
    }
  }
  @media only screen and (max-width: 500px) {
    .cookie-banner__inner {
      width: auto;
    }
  }
</style>
